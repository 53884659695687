import React from 'react';
import { v4 } from 'uuid';
import OutogingMessage from '../CRM/containers/CRMDashboard/components/ConversationsStreamContainer/components/OutgoingMessage/OutgoingMessage';

const BotResponse = (props) => {
  const { blocks } = props;

  return (
    <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
      {blocks?.length
        ? blocks.map((block, index) => {
            const { linkText, text, titleLabel, alternative_text, carousel_elements = [] } = block;

            const newMessage = {
              id: v4(),
              type: 'outgoing',
              content: linkText || text || titleLabel || carousel_elements[0]?.text,
              meta: { ...block },
              alternativeText: alternative_text,
            };

            return <OutogingMessage key={`msg_${index}`} message={newMessage} senderID={'bot'} />;
          })
        : null}
    </div>
  );
};

export default BotResponse;
