import React from 'react';

import Modal from 'pages/Flow/components/Modal';
import Dialog from 'pages/Flow/components/Dialog';

import './InformationWindow.scss';

const InformationWindow = (props) => {
  const { clickedIntegration, InformationWindowText, close } = props;

  return (
    <Modal onHide={close}>
      <Dialog
        onClose={close}
        headerView={<div className="MSTeamsConfigurationDialog_header">{clickedIntegration}</div>}
        contentView={
          <div className="InformationWindow">
            <h3 className="information-window-text">{InformationWindowText}</h3>
          </div>
        }
      />
    </Modal>
  );
};

export default InformationWindow;
