import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import ExternalConversationCard from './components/ExternalConversationCard';
import InternalConversationCard from './components/InternalConversationCard';

import {
  getChatHistory,
  removeFromExternalAllUnread,
  removeFromInternalAllUnread,
  removeFromUnassignedUnread,
  removeFromYouUnread,
  setConversationReadStatus,
  setCurrentConversation,
} from 'store/crm/actions';
import { selectCurrentConversation, selectInboxFolder } from 'store/crm/selectors';

import { processMessage } from 'utils/crm';
import ConversationCard from '../ConversationCard';

import './Conversation.scss';
import { integrationTitle } from '../../pages/ProjectDetails/components/Integrations/Integrations';

const Conversation = (props) => {
  const {
    inboxFolder,
    conversation,
    conversation: {
      channel_id,
      department_name,
      user_display_name,
      last_message,
      user_id,
      conversation_id,
      is_conversation_read,
      subject: conversationSubject,
      last_event_timestamp,
    },
    currentConversation,
    index,
  } = props;

  const { id: projectId } = useParams();
  const [lastMessage, setLastMessage] = useState('');
  const [elapsedTime, setElapsedTime] = useState('');
  const [minuteInterval, setMinuteInterval] = useState(Date.now());
  let navigate = useNavigate();
  const url = useResolvedPath('').pathname;

  useEffect(() => {
    const { parsedMessage } = processMessage(last_message);
    setLastMessage(parsedMessage);
    getElapsedTime(last_event_timestamp);
  }, [last_message, elapsedTime, lastMessage, setLastMessage]);

  useEffect(() => {
    const { parsedMessage } = processMessage(last_message);
    setLastMessage(parsedMessage);
    getElapsedTime(last_event_timestamp);
    const interval = refreshElapsedTimes();
    return () => {
      clearInterval(interval);
    };
  }, [minuteInterval, elapsedTime, last_message]);

  const getElapsedTime = (lastEventTimestamp) => {
    let deltaTime = differenceInMinutes(Date.now(), lastEventTimestamp);

    if (deltaTime < 60) {
      deltaTime = differenceInMinutes(Date.now(), lastEventTimestamp) + ' min';
    } else if (deltaTime >= 60 && deltaTime < 1440) {
      deltaTime = differenceInHours(Date.now(), lastEventTimestamp) + ' h';
    } else if (deltaTime >= 1440) {
      deltaTime = differenceInDays(Date.now(), lastEventTimestamp) + ' d';
    }
    setElapsedTime(deltaTime);
  };

  const refreshElapsedTimes = () => {
    return setInterval(() => setMinuteInterval(Date.now()), 60000);
  };

  const removeFromUnread = (conversation_id) => {
    const { submenu, file } = inboxFolder;
    if (submenu === 'External' && file.value === 'you') {
      props.removeFromYouUnread({ conversation_id });
      props.removeFromExternalAllUnread({ conversation_id });
    }
    if (submenu === 'External' && file.value === 'unassigned') {
      props.removeFromUnassignedUnread({ conversation_id });
      props.removeFromExternalAllUnread({ conversation_id });
    }
    if (submenu === 'External' && file.value === 'external_all') {
      props.removeFromExternalAllUnread({ conversation_id });
      props.removeFromYouUnread({ conversation_id });
      props.removeFromUnassignedUnread({ conversation_id });
    }
    if (submenu === 'Internal' && file.value === 'internal_all') {
      props.removeFromInternalAllUnread({ conversation_id });
    }
  };

  const handleClick = async () => {
    props.setConversationReadStatus({ conversation_id, user_id, project_id: projectId });
    removeFromUnread(conversation_id);
    const historyResponseAction = await props.setChatHistory({
      user_id,
      project_id: projectId,
      conversation_id,
    });
    props.setCurrentConversation(historyResponseAction?.payload?.conversation_data || conversation);
    const clean = url.substring(0, url.indexOf('inbox') + 5);
    const conversationPath = `${clean}/${user_id}_${conversation_id}`;
    navigate(conversationPath);
  };

  const renderConversationCard = () => {
    if (inboxFolder?.submenu === 'External') {
      return (
        <ExternalConversationCard
          elapsedTime={elapsedTime}
          isConversationRead={is_conversation_read}
          lastMessage={lastMessage}
          departmentName={department_name}
          channelName={integrationTitle[channel_id]}
          userDisplayName={user_display_name}
          isActive={currentConversation.conversation_id === conversation_id}
        />
      );
    }
    if (inboxFolder?.submenu === 'Internal') {
      return (
        <InternalConversationCard
          elapsedTime={elapsedTime}
          isConversationRead={is_conversation_read}
          lastMessage={lastMessage}
          departmentName={department_name}
          conversationSubject={conversationSubject}
          isActive={currentConversation.conversation_id === conversation_id}
        />
      );
    }
    return <div></div>;
  };

  return (
    <ConversationCard>
      <div className="Conversation" onClick={handleClick}>
        <div id={index} className="conversation-item">
          {renderConversationCard()}
        </div>
      </div>
    </ConversationCard>
  );
};

const mapStateToProps = (state) => ({
  currentConversation: selectCurrentConversation(state),
  inboxFolder: selectInboxFolder(state),
});

const mapDispatchToProps = {
  setCurrentConversation,
  setChatHistory: getChatHistory,
  setConversationReadStatus,
  removeFromYouUnread,
  removeFromUnassignedUnread,
  removeFromExternalAllUnread,
  removeFromInternalAllUnread,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
