import React, { useState } from 'react';

import languageMap from 'Language/Language';
import InputFieldsForModels from '../InputFieldsForModels';
import { AddButton } from '../../../../../../components/common/Button';
import { IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import DeleteConfirmationModal from '../../../../../../components/ConfirmationModal/DeleteConfirmationModal';

const DEFAULT_MODEL = {
  provider: '',
  model_name: '',
  api_key: '',
  api_base: '',
};
const TextTabGenerativeAIConfiguration = ({ setting, handleSyncSettings, values }) => {
  const [modelValues, setModelValues] = useState(values || []);
  const modelOptions = [
    { label: 'Azure', value: 'azure' },
    { label: 'OpenAI', value: 'openai' },
  ];

  let inputTitles = [
    { label: languageMap.modelName, value: '', prop: 'model_name' },
    { label: languageMap.apiKey, value: '', prop: 'api_key' },
    { label: languageMap.endpoint, value: '', prop: 'api_base' },
  ];

  const handleValuesChange = (index) => (values) => {
    const newValues = [...modelValues];
    newValues[index] = values;
    setModelValues(newValues);
  };
  const handleAdd = () => {
    const newValues = [...modelValues];
    newValues.push(DEFAULT_MODEL);
    setModelValues(newValues);
    handleSyncSettings(setting, newValues);
  };
  const handleDelete = (index) => () => {
    const newValues = [...modelValues];
    newValues.splice(index, 1);
    setModelValues(newValues);
    handleSyncSettings(setting, newValues);
  };

  const handleSave = (index) => (data) => {
    if (data) {
      const newValues = [...modelValues];
      newValues[index] = data;
      handleSyncSettings(setting, newValues);
    } else {
      handleSyncSettings(setting, modelValues);
    }
  };

  return (
    <div className="OCRConfigurationDialog">
      <div>
        <AddButton onClick={handleAdd} text={languageMap.addNew} icon="add" />
      </div>

      {modelValues.map((item, index) => {
        const provider = item.provider;
        const otherValues = { ...item };
        delete otherValues.provider;
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start' }} key={index}>
            <InputFieldsForModels
              options={modelOptions}
              inputTitles={inputTitles}
              inputValues={otherValues || {}}
              selectedProvider={provider}
              handleValuesChange={handleValuesChange(index)}
              handleSave={handleSave(index)}
            />
            <DeleteConfirmationModal
              onDelete={handleDelete(index)}
              trigger={
                <IconButton
                  style={{ marginTop: 16 }}
                  color="secondary"
                  title={languageMap.delete}
                  size="small"
                >
                  <Delete />
                </IconButton>
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default TextTabGenerativeAIConfiguration;
