import React, { useState } from 'react';
import { TextArea, Icon } from 'semantic-ui-react';
import FormGroup from 'components/common/FormGroup';
import Axios from 'axios';
import languageMap from 'Language/Language';

import { FILE_UPLOAD_ENDPOINT, UPLOAD_SCOPE_PUBLIC_PROJECT_FILES } from 'utils/constants/index';
import './CarouselEditorSlide.scss';
import CarouselSlide from '../../../../../../../CRM/containers/CRMDashboard/components/ConversationsStreamContainer/components/MessageRenderer/Carousel/CarouselSlide/CarouselSlide';
import AcceptBrowse from '../../../../../../../../components/Browse/AcceptBrowse';
import InputText from '../../../../../../../../components/common/input/InputText';

const inputStyle = {
  marginTop: 5,
};
const CarouselEditorSlide = ({
  onSlideChange,
  slide,
  removeSlide,
  index,
  slidesNumber,
  setCurrentSlide,
  fallbackImage,
  actionButton,
  projectId,
}) => {
  const { image, url, text } = slide;
  const [imageURL, setImageURL] = useState(image);
  const isFirstSlide = index === 0;
  const isLastSlide = index === slidesNumber - 1;

  const [currentUrl, setCurrentUrl] = useState(url);

  const saveSlide = (fieldToChange) => {
    onSlideChange({ ...slide, ...fieldToChange });
  };

  const onBlur = (field) => (e) => {
    if (e.target.value !== slide[field]) {
      saveSlide({ [field]: e.target.value });
    }
  };

  const onUrlChange = (name, value) => {
    setCurrentUrl(value);
  };

  const onImageChange = (name, value) => {
    setImageURL(value);
  };

  const handleImageURLChange = (newString) => {
    saveSlide({ image: newString });
  };

  const saveStoredImage = async (formData) => {
    const result = await Axios.post(FILE_UPLOAD_ENDPOINT + '/' + projectId, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
    if (result.data?.link) {
      handleImageURLChange(result.data.link);
    }
  };

  const handleImageUpload = (picture) => {
    let formData = new FormData();
    formData.append('file', picture[0]);
    formData.append('scope', UPLOAD_SCOPE_PUBLIC_PROJECT_FILES);
    saveStoredImage(formData);
  };

  const renderRemoveSlideButton = () => {
    return (
      <Icon className="CarouselEditorSlide_Header_DeleteIcon" name="delete" onClick={removeSlide} />
    );
  };

  const scrollToNextSlide = () => {
    setCurrentSlide(index + 1);
  };

  const scrollToPreviousSlide = () => {
    setCurrentSlide(index - 1);
  };

  const renderScrollBackButton = () => {
    return (
      !isFirstSlide && (
        <Icon
          className="CarouselEditorSlide_Header_Navigation_ButtonColumn_Button"
          name="arrow alternate circle left outline"
          onClick={scrollToPreviousSlide}
        />
      )
    );
  };

  const renderScrollForwardButton = () => {
    return (
      !isLastSlide && (
        <Icon
          className="CarouselEditorSlide_Header_Navigation_ButtonColumn_Button"
          name="arrow alternate circle right outline"
          onClick={scrollToNextSlide}
        />
      )
    );
  };

  const renderCarouselNavigationControls = () => {
    return (
      <div className="CarouselEditorSlide_Header_Navigation">
        <div className="CarouselEditorSlide_Header_Navigation_ButtonColumn">
          {renderScrollBackButton()}
        </div>
        <div className="CarouselEditorSlide_Header_Navigation_TitleColumn">
          <span className="CarouselEditorSlide_Header_Navigation_TitleColumn_Title">
            {languageMap.image} {index + 1} / {slidesNumber}
          </span>
        </div>
        <div className="CarouselEditorSlide_Header_Navigation_ButtonColumn">
          {renderScrollForwardButton()}
        </div>
      </div>
    );
  };

  const renderSlideHeader = () => {
    return (
      <div className="CarouselEditorSlide_Header">
        {renderCarouselNavigationControls()}
        {renderRemoveSlideButton()}
      </div>
    );
  };

  const renderSlideForm = () => {
    return (
      <div style={{ flexGrow: 1 }}>
        <FormGroup labelText={languageMap.image}>
          <div className="CarouselEditorBlock_ImageContainer">
            <InputText
              style={inputStyle}
              className="CarouselEditorBlock_Image"
              value={imageURL}
              onValueChanged={onImageChange}
              onBlur={(e) => handleImageURLChange(e.target.value)}
            />
            <AcceptBrowse
              style={{ marginLeft: 8 }}
              text={languageMap.chooseImage}
              onBrowse={handleImageUpload}
              accepts={['.jpeg', '.jpg', '.png', '.gif']}
            />
          </div>
        </FormGroup>
        <FormGroup labelText={languageMap.titleURL}>
          <InputText
            style={inputStyle}
            className="CarouselEditorBlock_TitleURL"
            defaultValue={currentUrl}
            onValueChanged={onUrlChange}
            onBlur={onBlur('url')}
          />
        </FormGroup>
        <FormGroup labelText={languageMap.text}>
          <TextArea
            className="CarouselEditorBlock_Input"
            defaultValue={text}
            minheight={37}
            rows={8}
            onBlur={onBlur('text')}
          />
        </FormGroup>
      </div>
    );
  };
  return (
    <div className="CarouselEditorSlide">
      {renderSlideHeader()}
      <div style={{ display: 'flex' }}>
        {renderSlideForm()}
        <CarouselSlide
          index={0}
          slide={slide}
          style={{ width: 240 }}
          width={240}
          fallbackImage={fallbackImage}
          actionButton={actionButton}
        />
      </div>
    </div>
  );
};

export default CarouselEditorSlide;
