import React, { useState, useEffect, useContext, useRef } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  InputBase,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DoneIcon from '@mui/icons-material/Done';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SelectIcon from 'assets/svg/SelectIcon';
import languageMap from 'Language/Language';

import { hasAccess } from 'services/userPermissions';
import { setUserName } from '../../../../../../../SettingsMenu/components/ComponentsCommonFunctions';

import {
  setConversationStatus,
  setAssignAgent,
  setAssignDepartment,
  setCurrentConversation,
  resetCurrentConversation,
  getChatHistory,
} from 'store/crm/actions';
import {
  selectInboxFolder,
  selectDepartments,
  selectDepartmentsIDs,
  selectCurrentConversation,
  selectConversationAllParticipants,
  selectCurrentUser,
  selectIsMobile,
} from 'store/crm/selectors';

import { isCurrentUserAdmin } from 'utils/utils';

import './ConversationsStreamHeader.scss';

import { displayConversationContext } from 'containers/ConversationsInboxContainer/ConversationsInboxContainer';
import clsx from 'clsx';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import ConversationUser from '../ConversationUser/ConversationUser';
import ResizeObserver from 'resize-observer-polyfill';

const unassignedAgent = {
  agent_name: 'Unassigned',
  agent_id: -1,
};
const headerShrunkWidthThreshold = 500;

const CustomSelectInput = styled(InputBase)(({ theme }) => ({
  marginRight: 9,
  marginLeft: 0,
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: '1px solid #eaeef4',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '10px 26px 10px 12px',
    fontFamily: 'DM Sans',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'left',
    color: '#092C4C',
    width: '100%',
  },
}));

const ConversationsStreamHeader = (props) => {
  const [department, setDepartmentLocal] = useState(null);
  const [agent, setAgentLocal] = useState(null);
  const [agents, setAgentsForDepartmentLocal] = useState([]);
  const [isDepartmentOpen, setDepartmentOpen] = useState(false);
  const [isAgentOpen, setAgentOpen] = useState(false);
  const elementRef = useRef(null);
  const [headerShrunk, setHeaderShrunk] = useState(false);
  const { setDisplayConversationDetails } = useContext(displayConversationContext);
  const navigate = useNavigate();
  const url = useResolvedPath('').pathname;
  const { id: projectId } = useParams();

  const {
    inboxFolder: { submenu },
    currentConversation,
    currentConversation: {
      assignee,
      department_id_assigned,
      department_name,
      user_id,
      conversation_id,
      user_display_name,
      channel_id,
    },
    participants,
    currentUser,
    departmentsIDs,
    setStatus,
    isMobile,
  } = props;

  const getNewAgentParams = (value) => {
    return {
      user_id,
      conversation_id,
      source_assignee: assignee,
      destination_assignee: value,
      current_department: department_id_assigned,
    };
  };

  const getNewDepartmentParams = (value) => {
    return {
      user_id,
      conversation_id,
      current_assignee: assignee,
      source_department_id: department_id_assigned,
      destination_department_id: value,
    };
  };

  useEffect(() => {
    const getDepartmentUsers = async () => {
      const response = await axios.get('/internal_api/department/get_department_users', {
        params: {
          project_id: projectId,
          department_id: department_id_assigned,
        },
      });

      let auxDataSet = response.data;
      let tempAgentsArray = [];

      for (let i = 0; i < auxDataSet?.length; i++) {
        let tempAgentsObject = {};
        let tempPermissionsObject = {};
        let temporaryName = '';

        temporaryName = setUserName(auxDataSet, i);

        tempAgentsObject.agent_name = temporaryName;
        tempAgentsObject.agent_id = auxDataSet[i].id || auxDataSet[i].agent_id;
        tempPermissionsObject[projectId] = auxDataSet[i]?.permission;

        if (
          hasAccess(projectId, tempPermissionsObject, 'inbox') ||
          auxDataSet[i].agent_id === -1 ||
          auxDataSet[i].agent_id === 0
        ) {
          tempAgentsArray.push(tempAgentsObject);
        }
      }

      setAgentsForDepartmentLocal(tempAgentsArray);
    };
    getDepartmentUsers();

    if (department_id_assigned) {
      setDepartmentLocal({
        department_id: department_id_assigned,
        department_name: department_name,
      });

      const assignedDepartment = props.departments.find(
        (item) => item.department_id === department_id_assigned
      );

      let assignedAgent = assignedDepartment?.agents.find((item) => {
        return item.agent_id === currentConversation.assignee;
      });
      if (assignedAgent === -1) {
        assignedAgent = unassignedAgent;
      }

      setAgentLocal(assignedAgent);
    }
  }, [
    user_id,
    conversation_id,
    department_id_assigned,
    currentConversation.assignee,
    department_name,
    props.departments,
  ]);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    const element = elementRef?.current;
    let observer = null;
    if (element) {
      observer = new ResizeObserver((entries) => {
        let entry = entries[0]; // We only observe the current element - the conversation stream header
        setHeaderShrunk(entry.contentRect.width <= headerShrunkWidthThreshold);
      });
      observer.observe(element);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const handleDepartmentChange = async (e, event) => {
    const { value, name } = event.props;

    setDepartmentLocal({ department_id: value, department_name: name });
    await setAssignDepartment(getNewDepartmentParams(value));

    const { agents } = props.departments.find((item) => item.department_id === value);
    setAgentsForDepartmentLocal(agents);

    setAgentLocal(unassignedAgent);

    const isAgentIncludedInDepartment = departmentsIDs.includes(value);

    if (!isAgentIncludedInDepartment) {
      props.setCurrentConversation();
    }
  };

  const handleAgentChange = async (e, event) => {
    const { value, name } = event.props;

    setAgentLocal({ agent_id: value, agent_name: name });

    if (assignee !== value && currentUser.user_id === assignee) {
      props.setCurrentConversation();
    }
    await setAssignAgent(getNewAgentParams(value));
    if (assignee !== value && currentUser.user_id === value) {
      const historyResponseAction = await props.getChatHistory({
        user_id,
        project_id: projectId,
        conversation_id,
      });
      if (historyResponseAction?.payload?.conversation_data) {
        props.setCurrentConversation(historyResponseAction.payload.conversation_data);
      }
    }
  };

  const handleCloseConversationClick = async () => {
    const { conversation_status, user_id } = currentConversation;
    let newConversationStatus;
    conversation_status === 1 ? (newConversationStatus = 0) : (newConversationStatus = 1);

    props.setCurrentConversation();
    await setStatus({ newConversationStatus, user_id, conversation_id });
    navigate(url.substring(0, url.indexOf('inbox') + 5));
  };

  const handleDisplayDetailsClick = () => {
    props.handleConversationDetailsButtonClick();
  };

  const renderDepartment = () => {
    const { departments } = props;

    if (Object.keys(departments)?.length > 0) {
      return departments.map((item) => (
        <MenuItem
          key={`agent_${item.department_id}`}
          value={item.department_id}
          name={item.department_name}
        >
          {item.department_name}
        </MenuItem>
      ));
    } else {
      return <div />;
    }
  };

  const resetDisplayConversation = () => {
    props.resetCurrentConversation();
  };

  const displayDetails = () => {
    isMobile && setDisplayConversationDetails(true);
  };

  const renderAgents = () => {
    if (agents?.length > 0) {
      return agents.map((item) => (
        <MenuItem key={`agent_${item.agent_id}`} value={item.agent_id} name={item.agent_name}>
          {item.agent_name}
        </MenuItem>
      ));
    }
  };

  const handleDepartmentOpen = () => {
    setDepartmentOpen(true);
  };

  const handleDepartmentClose = () => {
    setDepartmentOpen(false);
  };

  const renderDepartmentSelect = () => {
    return (
      <FormControl variant="standard">
        {!headerShrunk && (
          <InputLabel id="conversation-inbox-card-departments-label" shrink={true}>
            {languageMap.department}
          </InputLabel>
        )}

        <Select
          className="department-select"
          labelId="conversation-inbox-card-departments-label"
          id="conversation-inbox-card-departments"
          value={department === null ? '' : `${department?.department_id}`}
          open={isDepartmentOpen}
          onChange={handleDepartmentChange}
          onOpen={handleDepartmentOpen}
          onClose={handleDepartmentClose}
          input={<CustomSelectInput />}
          IconComponent={() => (
            <SelectIcon
              className={clsx(
                'MuiSvgIcon-root',
                'MuiSelect-icon select-label',
                isDepartmentOpen && 'MuiSelect-iconOpen'
              )}
            />
          )}
          sx={{
            '& .MuiSelect-icon': {
              position: 'absolute',
              right: '10px',
            },
          }}
        >
          {renderDepartment()}
        </Select>
      </FormControl>
    );
  };

  const handleAgentOpen = () => {
    setAgentOpen(true);
  };

  const handleAgentClose = () => {
    setAgentOpen(false);
  };

  const renderAgentSelect = () => {
    return (
      <FormControl variant="standard">
        {!headerShrunk && (
          <InputLabel id="conversation-inbox-card-agents-label">{languageMap.agent}</InputLabel>
        )}
        <Select
          className="department-select"
          labelId="conversation-inbox-card-agents-label"
          id="conversation-inbox-card-agents"
          value={agent === null ? '' : `${agent?.agent_id}`}
          onChange={handleAgentChange}
          onOpen={handleAgentOpen}
          onClose={handleAgentClose}
          input={<CustomSelectInput />}
          IconComponent={() => (
            <SelectIcon
              className={clsx(
                'MuiSvgIcon-root',
                'MuiSelect-icon select-label',
                isAgentOpen && 'MuiSelect-iconOpen'
              )}
            />
          )}
          sx={{
            '& .MuiSelect-icon': {
              position: 'absolute',
              right: '10px',
            },
          }}
        >
          {renderAgents()}
        </Select>
      </FormControl>
    );
  };

  const isExternalOrAdmin =
    submenu !== 'Internal' ||
    (submenu === 'Internal' && isCurrentUserAdmin(participants.all_participants, currentUser));
  return (
    <div
      className={clsx('ConversationStreamHeader', isMobile && 'ConversationStreamHeaderIsMobile')}
      ref={elementRef}
      style={headerShrunk ? { padding: '5px 15px 5px 15px' } : {}}
    >
      <div className="header-icon">
        {isMobile && (
          <IconButton onClick={resetDisplayConversation} size="large">
            <ArrowBackIcon />
          </IconButton>
        )}
        {submenu !== 'Internal' && (
          <ConversationUser
            userDisplayName={user_display_name}
            userId={user_id}
            projectId={projectId}
            channel_id={channel_id}
            isMobile={isMobile}
          />
        )}
      </div>

      <div
        className={clsx(
          'header-conversation-card-inbox-wrapper',
          isMobile && 'header-conversation-card-inbox-wrapper-isMobile'
        )}
        style={headerShrunk ? { flexWrap: 'wrap' } : {}}
      >
        {submenu !== 'Internal' && renderDepartmentSelect()}
        {submenu !== 'Internal' && renderAgentSelect()}

        {isExternalOrAdmin && isMobile && (
          <Button
            className="close-conversation regular-text"
            onClick={handleCloseConversationClick}
            variant="text"
          >
            {currentConversation.conversation_status === 1 && (
              <DoneIcon style={{ color: '#56595f' }} />
            )}
            {currentConversation.conversation_status === 0 && (
              <MailOutlineIcon style={{ color: '#56595f' }} />
            )}
          </Button>
        )}
        {isMobile && (
          <IconButton className="InfoIcon" onClick={displayDetails} size="large">
            <InfoOutlinedIcon />
          </IconButton>
        )}

        {isExternalOrAdmin && !isMobile && (
          <>
            <Button
              className="close-conversation dm-sans-500"
              onClick={handleCloseConversationClick}
              variant="text"
            >
              {currentConversation.conversation_status === 1 && (
                <div className="close-conversation-button">
                  <DoneIcon className="close-conversation-button-icon" />
                  {languageMap.close}
                </div>
              )}
              {currentConversation.conversation_status === 0 && (
                <div className="close-conversation-button">
                  <ArrowUpwardIcon className="close-conversation-button-icon" />
                  {languageMap.reopen}
                </div>
              )}
            </Button>
            <Tooltip
              title={`${
                props.conversationDetails ? languageMap.hideDetails : languageMap.showDetails
              }`}
            >
              <Button
                className="close-conversation"
                onClick={handleDisplayDetailsClick}
                variant="text"
                style={{ width: 40, height: 40, marginLeft: 9 }}
              >
                <div className="close-conversation-button regular-text">
                  <MoreHorizIcon className="close-conversation-button-icon" />
                </div>
              </Button>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  inboxFolder: selectInboxFolder(state),
  departments: selectDepartments(state),
  departmentsIDs: selectDepartmentsIDs(state),
  currentConversation: selectCurrentConversation(state),
  currentUser: selectCurrentUser(state),
  participants: selectConversationAllParticipants(state),
  isMobile: selectIsMobile(state),
});

const mapDispatchToProps = {
  setStatus: setConversationStatus,
  setCurrentConversation,
  resetCurrentConversation,
  getChatHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsStreamHeader);
