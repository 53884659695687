import React from 'react';

import DropDown from 'components/common/DropDown';
import InputText from 'components/common/input/InputText';
import languageMap from 'Language/Language';

import './InputFieldsForModels.scss';

const InputFieldsForModels = (props) => {
  const { options, inputTitles, handleValuesChange, handleSave, inputValues, selectedProvider } =
    props;
  const handleInputChange = (label, value) => {
    const newInputValues = { ...inputValues, [label]: value };
    const data = { provider: selectedProvider, ...newInputValues };
    handleValuesChange(data);
  };

  const handleDropdownChange = (value) => {
    const data = { provider: value, ...inputValues };
    handleValuesChange(data);
    handleSave(data);
  };

  const renderInputs = () => {
    return inputTitles.map((item, index) => {
      const title = item.label;
      const prop = item.prop;
      if (selectedProvider === 'openai' && prop === 'api_base') {
        return null;
      }
      return (
        <div key={index} className="InputFieldsForModelsRow">
          <div className="title">{title}</div>
          <InputText
            className="InputFieldsForModelsInput"
            placeholder={`Input ${title}`}
            type="text"
            value={inputValues[prop]}
            onChange={(e) => handleInputChange(prop, e.target.value)}
            onBlur={() => handleSave()}
          />
        </div>
      );
    });
  };

  return (
    <div className="InputFieldsForModels">
      <div className="InputFieldsForModelsRow">
        <div className="InputFieldsForModels_title">{languageMap.provider}</div>
        <DropDown
          className="InputFieldsForModelsInput"
          placeholder="Provider"
          type="text"
          clearable={false}
          options={options}
          value={selectedProvider || ''}
          onSelect={handleDropdownChange}
        />
      </div>
      {renderInputs()}
    </div>
  );
};

export default InputFieldsForModels;
