import React, { useCallback, useContext, useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import get from 'lodash/get';
import Card from 'components/common/Card';
import AnnotationUtterance from 'pages/Annotations/AnnotationUtterance';
import { IntentsByNodeContext } from 'pages/Annotations';
import './AnnotationCard.scss';
import { isEmpty, startsWith, endsWith } from 'lodash';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import languageMap from 'Language/Language';
import { AddButton } from '../../../components/common/Button';

const AnnotationCard = ({
  item,
  updateAnnotation,
  deleteAnnotation,
  validateAnnotation,
  annotationTab,
  setResponseModalOpen,
  setBotResponse,
  onCheck,
  checked,
}) => {
  const { annotation_id: annotationId, utterance, intent_id, initial_node_id, bot_response } = item;
  const intentsMap = useContext(IntentsByNodeContext);

  const getAppId = useCallback(
    (intent_id) => {
      if (Object.keys(intentsMap).length === 0) {
        return -1;
      }

      if (!intentsMap[initial_node_id]) {
        return -1;
      }

      for (const intent of intentsMap[initial_node_id]) {
        if (intent.intent_id === intent_id) {
          return intent.app_id;
        }
      }
    },
    [intentsMap, initial_node_id]
  );

  const setUtterance = useCallback(
    (utterance) => {
      const newItem = { ...item, utterance };
      updateAnnotation(newItem);
    },
    [item, updateAnnotation]
  );

  const setIntent = useCallback(
    (new_intent_id) => {
      let newItem = { ...item, intent_id: new_intent_id };

      const resetUtterance = () => {
        const getEntityRegex = /({.+?})/g;
        let chunks = utterance
          .split(getEntityRegex)
          .filter((chunk) => !isEmpty(chunk))
          .map((chunk) => {
            if (startsWith(chunk, '{') && endsWith(chunk, '}')) {
              return chunk.replace(/\{|\}/g, '').split(':')[1];
            } else {
              return chunk;
            }
          });

        return chunks.join('');
      };

      if (getAppId(intent_id) !== getAppId(new_intent_id)) {
        const utterance = resetUtterance();
        newItem = { ...newItem, utterance };
      }

      updateAnnotation(newItem);
    },
    [item, updateAnnotation, intent_id, getAppId, utterance]
  );

  const intentOptions = useMemo(() => {
    const intents = get(intentsMap, initial_node_id, []);

    return intents.map((item, index) => {
      return {
        text: item.intent_name,
        key: `${item.intent_id}+${index}`,
        value: item.intent_id,
      };
    });
  }, [initial_node_id, intentsMap]);

  let appId = getAppId(intent_id);

  return (
    <Card className="AnnotationCard">
      <div className="TextContainer">
        <AnnotationUtterance appId={appId} utterance={utterance} setUtterance={setUtterance} />
        {(annotationTab === 2 || annotationTab === 1) && (
          <div>
            <AddButton
              style={{ marginTop: 12 }}
              text={languageMap.showResponse}
              onClick={() => {
                setBotResponse([...bot_response]);
                setResponseModalOpen(true);
              }}
            />
          </div>
        )}
      </div>

      <div className="AnnotationCard_Actions">
        {intentOptions.length === 0 ? (
          <span style={{ color: 'red' }}>{languageMap.stateNoLongerExists}</span>
        ) : (
          <>
            <Dropdown
              placeholder={languageMap.selectClientType}
              search
              selection
              options={intentOptions}
              onChange={(e, { value }) => {
                setIntent(value);
              }}
              value={intent_id}
              loading={false}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => validateAnnotation(annotationId)}
            >
              {languageMap.validate}
            </Button>
          </>
        )}
        <Button color="secondary" onClick={() => deleteAnnotation(annotationId)}>
          {languageMap.delete}
        </Button>
        {onCheck && (
          <Checkbox color="primary" checked={checked} onChange={() => onCheck(annotationId)} />
        )}
      </div>
    </Card>
  );
};

export default AnnotationCard;
