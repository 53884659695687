import React, { Component } from 'react';
import Axios from 'axios';
import { Tooltip } from '@mui/material';

import withNotifications from 'components/withNotifications';
import Dialog from 'pages/Flow/components/Dialog';
import Modal from 'pages/Flow/components/Modal';
import Loader from 'components/common/Loader';
import WebchatSettings from 'pages/ProjectDetails/components/Integrations/components/WebchatConfigurationDialog/WebchatSettings';
import InputText from 'components/common/input/InputText';
import VoiceDropDown from './Voices';
import languageMap from 'Language/Language';

import './WebchatConfigurationDialog.scss';
import Tabs from '../../../../../../components/common/TabBar';
import { connect } from 'react-redux';
import extractLanguageInfo from '../../../../../../utils/extractLanguageInfo';
import ProactiveMessages from './ProactiveMessages';
import { MAIN_COLOR } from '../../../../../../mainTheme';
import CodeEditor from '../../../../../Flow/components/Script/CodeEditor';
import MessageBlocks from '../../../../../Flow/components/Message/MessageBlocks';
import { Dropdown } from 'semantic-ui-react';
import { AddButton } from '../../../../../../components/common/Button';
import { SIMPLE_TEXT_BLOCK } from '../../../../../Flow/components/Message/MessageBlocks/blocks-config';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const initialState = {
  client_key: '',
  feedback: false,
  voice_interaction: true,
  avatar: false,
  file_input_agent: true,
  file_input_bot: false,
  default_input: 'text',
  isLoading: true,
  disable_browser_STT_support: true,
  proactive_chat: false,
  proactive_chat_timer: 10,
  proactive_messages: [
    // id: message_id
    // messageProperties: { responseOptionsByLanguage: {}, replySuggestionsByLanguage: {} },
    // url: url for regex
  ],
  proactive_app_id: '',
  enable_history_restore: true,
  woocommerce_tracking: false,
  woocommerce_website: 'www.website.com',
  woocommerce_client_key: 'wk_test',
  woocommerce_client_secret: 'wc_test',
  voices: {},
  desktop_top_margin: '',
  desktop_bottom_margin: '',
  desktop_right_margin: '',
  desktop_minimized_bottom_margin: '',
  desktop_minimized_right_margin: '',
  theme_color: '#0530F9',
  inputPlaceholdersByLanguage: null,
  selectedLanguage: '',
  isLoadingRequest: false,
};
const BLOCK_TYPES_SIMPLE = [
  {
    type: SIMPLE_TEXT_BLOCK,
    text: languageMap.text,
  },
];

class WebchatConfigurationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.settings = [
      {
        title: languageMap.feedback,
        name: 'feedback',
      },
      {
        title: languageMap.fileInputAgent,
        name: 'file_input_agent',
      },
      {
        title: languageMap.fileInputBot,
        name: 'file_input_bot',
      },
      {
        title: languageMap.enableHistory,
        name: 'enable_history_restore',
      },
      {
        title: 'Woocommerce Tracking',
        name: 'woocommerce_tracking',
      },
    ];
  }

  async componentDidMount() {
    const { showError, projectId } = this.props;
    await Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: projectId,
        type_id: 2,
      },
    })
      .then((response) => {
        if (response.data.status === 'ok') {
          let data = JSON.parse(response.data.data);
          console.log(data);

          const {
            client_key,
            feedback,
            voice_interaction,
            file_input_agent,
            file_input_bot,
            default_input,
            disable_browser_STT_support,
            proactive_chat,
            proactive_chat_timer,
            enable_history_restore,
            woocommerce_tracking,
            woocommerce_website,
            woocommerce_client_key,
            woocommerce_client_secret,
            tts_voices,
            voices,
            desktop_top_margin,
            desktop_bottom_margin,
            desktop_right_margin,
            desktop_minimized_bottom_margin,
            desktop_minimized_right_margin,
            theme_color,
            proactive_messages,
            customStyling,
            inputPlaceholdersByLanguage,
            inputPlaceholderTranslation,
          } = data;
          // console.log(data);

          this.setState({
            client_key: client_key || '',
            feedback: feedback || false,
            voice_interaction: voice_interaction ?? true,
            ttsVoices: tts_voices,
            customStyling,
            voices: voices || {},
            file_input_agent: file_input_agent !== undefined ? file_input_agent : true,
            file_input_bot: file_input_bot !== undefined ? file_input_bot : false,
            default_input: default_input || 'text',
            disable_browser_STT_support: disable_browser_STT_support ?? true,
            proactive_chat: proactive_chat || false,
            proactive_chat_timer: proactive_chat ? proactive_chat_timer || '' : '',
            enable_history_restore:
              enable_history_restore !== undefined ? enable_history_restore : true,
            woocommerce_tracking: woocommerce_tracking !== undefined ? woocommerce_tracking : false,
            woocommerce_website: woocommerce_website !== undefined ? woocommerce_website : 'xx',
            woocommerce_client_key:
              woocommerce_client_key !== undefined ? woocommerce_client_key : 'xx',
            woocommerce_client_secret:
              woocommerce_client_secret !== undefined ? woocommerce_client_secret : 'xx',
            desktop_top_margin: desktop_top_margin ? desktop_top_margin : '20px',
            desktop_bottom_margin: desktop_bottom_margin ? desktop_bottom_margin : '20px',
            desktop_right_margin: desktop_right_margin ? desktop_right_margin : '20px',
            desktop_minimized_bottom_margin: desktop_minimized_bottom_margin
              ? desktop_minimized_bottom_margin
              : '20px',
            desktop_minimized_right_margin: desktop_minimized_right_margin
              ? desktop_minimized_right_margin
              : '20px',
            theme_color: theme_color ? theme_color : MAIN_COLOR,
            inputPlaceholdersByLanguage: inputPlaceholdersByLanguage || {},
            inputPlaceholderTranslation,
          });

          this.setState({
            isLoading: false,
            selectedLanguage: this.props.selectedLanguage,
            proactive_messages: proactive_messages || [],
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        showError(error.message);
        this.props.close();
      });
  }

  changeIsLoadingRequest = () => {
    this.setState({ isLoadingRequest: !this.state.isLoadingRequest });
  };

  onValueChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      this.handleSyncWebchatSettings(key);
    });
  };

  onPlaceholdersSave = (value) => {
    const { inputPlaceholdersByLanguage } = this.state;
    const syncAfterSetState = async () => {
      this.changeIsLoadingRequest(true);
      const response = await this.handleSyncWebchatSettings('inputPlaceholdersByLanguage');
      if (response.data.status === 'ok') {
        let data = JSON.parse(response.data.data);
        this.setState({ inputPlaceholdersByLanguage: data.inputPlaceholdersByLanguage });
      }
      this.changeIsLoadingRequest(false);
    };

    this.setState(
      {
        inputPlaceholdersByLanguage: {
          ...inputPlaceholdersByLanguage,
          [this.state.selectedLanguage]: value,
        },
      },
      syncAfterSetState
    );
  };
  onInputPlaceholderTranslationChange = (value) => {
    this.setState(
      {
        inputPlaceholderTranslation: value,
      },
      () => this.handleSyncWebchatSettings('inputPlaceholderTranslation')
    );
  };

  handleSyncWebchatSettings = (setting) => {
    const { showError } = this.props;

    return Axios.post('/internal_api/project/integration/update', {
      projectId: this.props.projectId,
      typeId: 2,
      column: 'data',
      value: JSON.stringify({
        [setting]: this.state[setting],
      }),
    }).catch((error) => showError(error.message));
  };

  handleSyncWebchatSettingsMultiple = (settings) => {
    const { showError } = this.props;

    Axios.post('/internal_api/project/integration/update', {
      projectId: this.props.projectId,
      typeId: 2,
      column: 'data',
      value: JSON.stringify(settings),
    }).catch((error) => showError(error.message));
  };

  handleCheckboxChange = (e) => {
    const setting = e.target.name;
    const { proactive_chat_timer } = this.state;
    const proactive_chat_timer_value =
      setting === 'proactive_chat' && this.state[setting]
        ? proactive_chat_timer
        : initialState.proactive_chat_timer;
    if (setting === 'proactive_chat') {
      this.setState({
        proactive_chat_timer: proactive_chat_timer_value,
      });
      if (!this.state.proactive_messages) {
        this.setState({
          proactive_messages: [],
        });
      }
    }
    this.setState(
      {
        [setting]: !this.state[setting],
      },
      () => {
        this.handleSyncWebchatSettings(setting);
      }
    );
  };

  onChangeLanguage = (e, { value }) => {
    this.setState({ selectedLanguage: value });
  };

  addAnimatedMessage = () => {
    const { selectedLanguage } = this.state;
    this.setState(
      {
        inputPlaceholderTranslation: true,
        inputPlaceholdersByLanguage: {
          [selectedLanguage]: [],
        },
      },
      async () => {
        this.changeIsLoadingRequest(true);
        await this.handleSyncWebchatSettings('inputPlaceholdersByLanguage');
        await this.handleSyncWebchatSettings('inputPlaceholderTranslation');
        this.changeIsLoadingRequest(false);
      }
    );
  };

  removeAnimatedMessage = () => {
    this.setState(
      {
        inputPlaceholderTranslation: null,
        inputPlaceholdersByLanguage: null,
      },
      async () => {
        this.changeIsLoadingRequest(true);
        await this.handleSyncWebchatSettings('inputPlaceholdersByLanguage');
        await this.handleSyncWebchatSettings('inputPlaceholderTranslation');
        this.changeIsLoadingRequest(false);
      }
    );
  };

  renderWebchatSettings = () => {
    return this.settings.map((setting) => {
      const { title, name } = setting;
      return (
        <WebchatSettings
          name={name}
          key={name}
          checked={this.state[name]}
          onChange={this.handleCheckboxChange}
        >
          {title}
        </WebchatSettings>
      );
    });
  };

  changePositionHandler = (event, key) => {
    this.setState({ [key]: event.target.value }, () => {
      this.handleSyncWebchatSettings(key);
    });
  };

  changePositionHandlerForMargins = (event, key) => {
    if (event.target.value.slice(-2) === 'px' || event.target.value.slice(-1) === '%') {
      this.setState({ [key]: event.target.value }, () => {
        this.handleSyncWebchatSettings(key);
      });
    } else {
      this.setState({ [key]: '' });
    }
  };

  renderChatPositionSettings = () => {
    const {
      desktop_top_margin,
      desktop_bottom_margin,
      desktop_right_margin,
      desktop_minimized_bottom_margin,
      desktop_minimized_right_margin,
    } = this.state;

    return (
      <div
        className="WebchatConfigurationDialog_Content_Options_ColumnRight_OptionContainer"
        style={{ margin: '5px', height: 'auto' }}
      >
        <div className="WebchatConfigurationDialog_Content_Options_ColumnRight_OptionContainer_OptionTitle">
          {languageMap.webchatPosition}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="postion_input_box_wrapper">
            {languageMap.topMargin}
            <Tooltip
              placement="top"
              classes={{ popper: 'tooltip_popper' }}
              title={languageMap.inputHelperText}
              style={{ zIndex: '9001 !important' }}
            >
              <input
                style={{ background: desktop_top_margin ? '' : 'red' }}
                className="postion_input_box"
                type="text"
                defaultValue={desktop_top_margin}
                onChange={(event) =>
                  this.changePositionHandlerForMargins(event, 'desktop_top_margin')
                }
              />
            </Tooltip>
          </div>
          <div className="postion_input_box_wrapper">
            {languageMap.rightMargin}
            <Tooltip
              placement="top"
              classes={{ popper: 'tooltip_popper' }}
              title={languageMap.inputHelperText}
              style={{ zIndex: '90009' }}
            >
              <input
                style={{ background: desktop_right_margin ? '' : 'red' }}
                className="postion_input_box"
                type="text"
                defaultValue={desktop_right_margin}
                onChange={(event) =>
                  this.changePositionHandlerForMargins(event, 'desktop_right_margin')
                }
              />
            </Tooltip>
          </div>
          <div className="postion_input_box_wrapper">
            {languageMap.bottomMargin}
            <Tooltip
              placement="top"
              classes={{ popper: 'tooltip_popper' }}
              title={languageMap.inputHelperText}
              style={{ zIndex: '90009' }}
            >
              <input
                style={{ background: desktop_bottom_margin ? '' : 'red' }}
                className="postion_input_box"
                type="text"
                defaultValue={desktop_bottom_margin}
                onChange={(event) =>
                  this.changePositionHandlerForMargins(event, 'desktop_bottom_margin')
                }
              />
            </Tooltip>
          </div>
          <div className="postion_input_box_wrapper">
            {languageMap.rightIconMargin}
            <Tooltip
              placement="top"
              classes={{ popper: 'tooltip_popper' }}
              title={languageMap.inputHelperText}
              style={{ zIndex: '90009' }}
            >
              <input
                style={{ background: desktop_minimized_right_margin ? '' : 'red' }}
                className="postion_input_box"
                type="text"
                defaultValue={desktop_minimized_right_margin}
                onChange={(event) =>
                  this.changePositionHandlerForMargins(event, 'desktop_minimized_right_margin')
                }
              />
            </Tooltip>
          </div>
          <div className="postion_input_box_wrapper">
            {languageMap.bottomIconMargin}
            <Tooltip
              placement="top"
              classes={{ popper: 'tooltip_popper' }}
              title={languageMap.inputHelperText}
              style={{ zIndex: '90009' }}
            >
              <input
                style={{ background: desktop_minimized_bottom_margin ? '' : 'red' }}
                className="postion_input_box"
                type="text"
                defaultValue={desktop_minimized_bottom_margin}
                onChange={(event) =>
                  this.changePositionHandlerForMargins(event, 'desktop_minimized_bottom_margin')
                }
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  renderThemeColorPicker = () => {
    const { theme_color } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          margin: '5px',
          height: 'auto',
          padding: 10,
          alignItems: 'center',
        }}
      >
        <div className="WebchatConfigurationDialog_Content_Options_ColumnRight_OptionContainer_OptionTitle">
          {languageMap.themeColor}
        </div>

        <div>
          <input
            style={{ marginLeft: 12 }}
            type="color"
            defaultValue={theme_color}
            onBlur={(event) => this.changePositionHandler(event, 'theme_color')}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      client_key,
      ttsVoices,
      voice_interaction,
      voices,
      disable_browser_STT_support,
      proactive_chat,
      proactive_chat_timer,
      selectedLanguage,
      proactive_messages,
      inputPlaceholdersByLanguage,
      isLoading,
      isLoadingRequest,
      customStyling,
      inputPlaceholderTranslation,
    } = this.state;
    const { close, projectId, languageOptions } = this.props;

    const htmlString = `<script type="text/javascript" src=${process.env.REACT_APP_WEBCHAT_URL} async clientkey=${client_key} projectid=${projectId}></script>`;
    const currentPlaceholders = inputPlaceholdersByLanguage?.[selectedLanguage];
    return (
      <Modal onHide={close}>
        <Dialog
          onClose={close}
          isLoading={isLoadingRequest}
          headerView={<div className="WebchatConfigurationDialog_Header">{languageMap.web}</div>}
          contentView={
            isLoading ? (
              <Loader />
            ) : (
              <Tabs>
                <div label={languageMap.settings}>
                  <div className="WebchatConfigurationDialog_Content">
                    <p>{languageMap.integrateTheWebchatByInsertingThisScript}</p>
                    <pre className="WebchatConfigurationDialog_Content_EmbedingScriptContainer">
                      <div>{htmlString}</div>
                    </pre>
                    <div className="WebchatConfigurationDialog_Content_Options">
                      <div className="WebchatConfigurationDialog_Content_Options_ColumnLeft">
                        {this.renderWebchatSettings()}
                      </div>
                      <div className="WebchatConfigurationDialog_Content_Options_ColumnRight">
                        {this.renderChatPositionSettings()}
                        {this.renderThemeColorPicker()}
                      </div>
                    </div>
                    <div hidden={!this.state.woocommerce_tracking}>
                      <div className="WoocommerceConfiguration">
                        <InputText
                          className="WoocommerceConfiguration_Input"
                          type="string"
                          step="any"
                          min="0"
                          title="Woocommerce Website"
                          name="woocommerce_website"
                          value={this.state.woocommerce_website}
                          onValueChanged={this.onValueChange}
                        />
                      </div>
                      <div className="WoocommerceConfiguration">
                        <InputText
                          className="WoocommerceConfiguration_Input"
                          type="string"
                          step="any"
                          min="0"
                          title="Woocommerce Client Key"
                          name="woocommerce_client_key"
                          value={this.state.woocommerce_client_key}
                          onValueChanged={this.onValueChange}
                        />
                      </div>
                      <div className="WoocommerceConfiguration">
                        <InputText
                          className="WoocommerceConfiguration_Input"
                          type="string"
                          step="any"
                          min="0"
                          title="Woocommerce Client Secret"
                          name="woocommerce_client_secret"
                          value={this.state.woocommerce_client_secret}
                          onValueChanged={this.onValueChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div label={languageMap.voice}>
                  <WebchatSettings
                    name={'voice_interaction'}
                    key={'voice_interaction'}
                    checked={this.state['voice_interaction']}
                    onChange={this.handleCheckboxChange}
                  >
                    {languageMap.voiceInteraction}
                  </WebchatSettings>
                  <WebchatSettings
                    name={'disable_browser_STT_support'}
                    key={'disable_browser_STT_support'}
                    checked={disable_browser_STT_support}
                    onChange={this.handleCheckboxChange}
                  >
                    {languageMap.disableBrowserStt}
                  </WebchatSettings>
                  {voice_interaction && disable_browser_STT_support && (
                    <VoiceDropDown
                      languageOptions={languageOptions}
                      voices={voices}
                      ttsVoices={ttsVoices}
                      onChange={this.handleSyncWebchatSettingsMultiple}
                    />
                  )}
                </div>
                <div label={languageMap.proactiveMessage}>
                  <div
                    style={{
                      display: 'flex',
                      padding: 10,
                      alignItems: 'center',
                      fontWeight: 600,
                      color: '#56595f',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 600,
                        color: '#56595f',
                      }}
                    >
                      {languageMap.inputPlaceholder}
                      {languageOptions.length > 1 && (
                        <Dropdown
                          className="languageDropdown"
                          selection
                          value={selectedLanguage}
                          options={languageOptions}
                          onChange={this.onChangeLanguage}
                        />
                      )}
                      {!currentPlaceholders && (
                        <AddButton
                          text={languageMap.addNewMessage}
                          icon="add"
                          style={{ marginLeft: 10, minWidth: 40, padding: 8 }}
                          onClick={this.addAnimatedMessage}
                        />
                      )}
                      {currentPlaceholders && (
                        <IconButton
                          onClick={this.removeAnimatedMessage}
                          size="small"
                          style={{ marginLeft: 10 }}
                        >
                          <Delete style={{ width: '30px', height: '30px' }} />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  {currentPlaceholders && (
                    <MessageBlocks
                      hideTitle={true}
                      messageId={''}
                      projectId={projectId}
                      options={currentPlaceholders}
                      voiceInteraction={false}
                      automaticTranslation={inputPlaceholderTranslation}
                      onChangeAutomaticTranslation={this.onInputPlaceholderTranslationChange}
                      onChange={this.onPlaceholdersSave}
                      selectedLanguage={selectedLanguage}
                      loadingChange={this.changeIsLoadingRequest}
                      blockTypes={BLOCK_TYPES_SIMPLE}
                    />
                  )}
                  <div
                    style={{ marginTop: 25, marginBottom: 10, borderTop: 'solid 1px lightgrey' }}
                  />

                  <WebchatSettings
                    name={'proactive_chat'}
                    key={'proactive_chat'}
                    checked={this.state['proactive_chat']}
                    onChange={this.handleCheckboxChange}
                  >
                    {languageMap.proactiveChat}
                  </WebchatSettings>

                  {proactive_chat && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          padding: 10,
                          alignItems: 'center',
                          fontWeight: 600,
                          color: '#56595f',
                        }}
                      >
                        {languageMap.proactiveChatTimer}
                        <InputText
                          style={{ marginLeft: 8 }}
                          type="number"
                          step="any"
                          min="0"
                          name="proactive_chat_timer"
                          value={proactive_chat_timer}
                          onValueChanged={this.onValueChange}
                          disabled={!proactive_chat}
                        />
                      </div>
                      <div style={{ padding: 10 }}>
                        <ProactiveMessages
                          proactive_messages={proactive_messages}
                          onChangeMessages={this.onValueChange}
                          changeIsLoadingRequest={this.changeIsLoadingRequest}
                          isLoadingRequest={isLoadingRequest}
                          selectedLanguage={selectedLanguage}
                          projectId={projectId}
                          onChangeLanguage={this.onChangeLanguage}
                          languageOptions={languageOptions}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div label={languageMap.styling}>
                  <div>
                    {languageMap.stylingInfo}
                    <br />
                    {languageMap.stylingExample}
                  </div>
                  <CodeEditor
                    customContainerStyle={{
                      width: '100%',
                      height: 'calc(100% - 60px)',
                    }}
                    onSave={(code) => this.onValueChange('customStyling', code)}
                    value={customStyling}
                  />
                </div>
              </Tabs>
            )
          }
        />
      </Modal>
    );
  }
}

export default connect(extractLanguageInfo)(withNotifications(WebchatConfigurationDialog));
