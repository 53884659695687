import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, Input, Modal } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import languageMap from 'Language/Language';
import { uploadFile } from 'utils/uploadFile';
import { getToken } from 'store/auth/selectors';
import { selectAllLanguages } from 'store/crm/selectors';
import { connect } from 'react-redux';
import axios from 'axios';

import { DocumentTable } from './DocumentTable';
import AcceptDrop from '../../../../../../components/AcceptDrop/AcceptDrop';
import AcceptBrowse from '../../../../../../components/Browse/AcceptBrowse';
import { AddButton } from '../../../../../../components/common/Button';

const ACCEPTS_DOC = [
  // --------------- documents:
  '.doc',
  'application/msword',

  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  '.odt',
  'application/vnd.oasis.opendocument.text',

  // --------------- sheets:
  '.xls',
  'application/vnd.ms-excel',

  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  '.ods',
  'application/vnd.oasis.opendocument.spreadsheet',
];

const UploadModal = (props) => {
  const [language, setLanguage] = useState(null);
  const { open, setOpen, files, setFiles } = props;
  const { onUpload } = props;

  const handleUpload = () => {
    if (!files.length) {
      alert('No file selected');
      return;
    }

    if (!language) {
      alert('You need to select a language');
      return;
    }

    onUpload && onUpload(files, language);
    setOpen(false);
  };

  const onChangeLanguage = (e, { value }) => {
    setLanguage(value);
  };

  const handleFileSelect = (files) => {
    let filesArray = [];
    files.forEach((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        alert('File too large, max 10MB allowed');
      } else {
        filesArray.push(file);
      }
    });
    console.log(filesArray);
    setFiles(filesArray);
  };

  const languageOptions = props.languages.map((entry) => {
    const name = entry.label;
    const code = entry.value;

    return {
      key: name,
      text: name,
      value: code,
    };
  });

  return (
    <Modal
      size="mini"
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
        setFiles([]);
      }}
      open={open}
      trigger={props.trigger}
    >
      <Modal.Header>{languageMap.uploadDocument}</Modal.Header>
      <Modal.Content>
        <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
          <AcceptBrowse
            text={languageMap.selectFiles}
            multiple
            onBrowse={handleFileSelect}
            accepts={ACCEPTS_DOC}
          />

          <div style={{ marginLeft: 10 }}>{files?.length + ' selected'}</div>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 14,
          }}
        >
          <span>Language</span>
          <Dropdown
            style={{ width: 200 }}
            placeholder={languageMap.selectLanguage}
            options={languageOptions}
            fluid
            selection
            search
            onChange={onChangeLanguage}
          />
        </div>
      </Modal.Content>

      <Modal.Actions>
        <AddButton onClick={handleUpload} text={languageMap.uploadFiles}></AddButton>
      </Modal.Actions>
    </Modal>
  );
};

const Documents = ({ projectId, scope, token, languages, setIsLoading }) => {
  const [search, setSearch] = useState(undefined);
  const [documents, setDocuments] = useState([]);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const onChangeSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 300),
    []
  );

  const handleDrop = (files) => {
    setFiles(files);
    setOpen(true);
  };

  const getDocuments = () => {
    axios
      .get('/internal_api/list_files', {
        params: { project_id: projectId, scope: scope },
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          const prefix = projectId + '/';
          let documents = response.data.map((row) => {
            if (row.name.startsWith(prefix)) {
              row.name = row.name.slice(prefix.length);
            }
            return row;
          });
          setDocuments(documents);
        }
      });
  };

  useEffect(getDocuments, []);

  const handleUpload = async (files, language) => {
    for (let i = 0; i < files.length; i++) {
      const found = documents.find((doc) => doc.name === files[i].name);
      if (found) {
        console.log(files[i]);
        alert(`File already exists (${found.name}), delete it first`);
        return;
      }
    }
    let filesToUpload = files.map((file) => ({ file, id: Math.random() * 1000 }));
    setDocuments((docs) => {
      let newDocs = [...docs];
      filesToUpload.forEach((upload) => {
        newDocs.unshift({
          name: upload.file.name,
          created_at: languageMap.uploading,
          id: upload.id,
        });
      });
      return newDocs;
    });
    const batches = [];
    for (let i = 0; i < filesToUpload.length; i += 5) {
      batches.push(filesToUpload.slice(i, i + 5));
    }
    for (const batch of batches) {
      try {
        await Promise.all(
          batch.map(({ file, id }) =>
            uploadFile(file, projectId, token, file.name, scope, language).then(() => {
              setDocuments((docs) => {
                let newDocuments = [...docs];
                let index = newDocuments.findIndex((doc) => doc.id === id);
                newDocuments[index] = { ...newDocuments[index], created_at: languageMap.uploaded };
                return newDocuments;
              });
            })
          )
        );
      } catch (err) {
        console.log(err);
      }
    }
    getDocuments();
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    axios
      .delete('/internal_api/delete_file', {
        params: {
          project_id: projectId,
          file_id: id,
          scope: scope,
        },
      })
      .then(() => {
        setIsLoading(false);
        getDocuments();
      })
      .catch(() => {
        setIsLoading(false);
        getDocuments();
      });
  };

  let filtered_documents = documents;
  if (search) {
    filtered_documents = documents.filter((x) => x.name.includes(search));
  }

  const handleDownload = (id, filename) => {
    setIsLoading(true);
    axios({
      url: '/internal_api/download_file',
      method: 'GET',
      responseType: 'blob',
      params: {
        project_id: projectId,
        scope: scope,
        file_id: id,
      },
    })
      .then((response) => {
        setIsLoading(false);
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        maxWidth: 1024,
        height: '100%',
      }}
    >
      <div className="DropContainer" style={{ marginTop: 20, position: 'relative' }}>
        <AcceptDrop message={languageMap.dropFiles} accepts={ACCEPTS_DOC} onDrop={handleDrop} />
        <div style={{ display: 'flex', marginTop: 20, paddingBottom: 20 }}>
          <Input
            placeholder={languageMap.search}
            icon="search"
            style={{ height: '39px', width: '300px' }}
            onChange={(e) => onChangeSearch(e.target.value)}
          />
          <div>
            <UploadModal
              open={open}
              setOpen={setOpen}
              files={files}
              setFiles={setFiles}
              languages={languages}
              onUpload={handleUpload}
              trigger={<AddButton style={{ marginLeft: 16 }} text={languageMap.upload}></AddButton>}
            />
          </div>
        </div>
      </div>

      <DocumentTable
        data={filtered_documents}
        languages={languages}
        projectId={projectId}
        onDelete={handleDelete}
        onDownload={handleDownload}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: getToken(state),
  languages: selectAllLanguages(state),
});

export default connect(mapStateToProps)(Documents);
