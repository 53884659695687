import React, { useState } from 'react';
import Axios from 'axios';
import languageMap from 'Language/Language';

import Dialog from 'pages/Flow/components/Dialog';
import Modal from 'pages/Flow/components/Modal';
import Tabs from '../../../../../../components/common/TabBar';
import Loader from 'components/common/Loader';

import SetttingTabGenerativeAIConfiguration from './SetttingTabGenerativeAIConfiguration';
import TextTabGenerativeAIConfiguration from './TextTabGenerativeAIConfiguration';

import './GenerativeAIConfigurationDialog.scss';
import useComponentDidMount from '../../../../../../utils/useComponentDidMount';

const LANGUAGE_KEY = 'processing_language';
const MODELS_KEY = 'text_generation_models';
const GenerativeAIConfigurationDialog = ({ close, projectId }) => {
  const [isLoadingInit, setIsLoadingInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [genaiSettings, setGenaiSettings] = useState({});
  useComponentDidMount(() => {
    Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: projectId,
        type_id: 14,
      },
    })
      .then((resp) => {
        setIsLoadingInit(false);
        let data = JSON.parse(resp.data.data || '{}');
        setGenaiSettings({ ...data });
      })
      .catch((error) => {
        setIsLoadingInit(false);
        console.log(error.message);
      });
  });

  const handleSyncIntegrationSettings = (setting, value) => {
    setIsLoading(true);
    setGenaiSettings({ ...genaiSettings, [setting]: value });
    Axios.post('/internal_api/project/integration/update', {
      projectId,
      typeId: 14,
      column: 'data',
      value: JSON.stringify({
        [setting]: value,
      }),
    })
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
      });
  };

  const tabs = [
    {
      label: languageMap.settings,
      component: (
        <SetttingTabGenerativeAIConfiguration
          setting={LANGUAGE_KEY}
          language={genaiSettings[LANGUAGE_KEY] || null}
          handleSyncSettings={handleSyncIntegrationSettings}
          changeIsLoading={setIsLoading}
        />
      ),
    },
    {
      label: languageMap.textGeneration,
      component: (
        <TextTabGenerativeAIConfiguration
          setting={MODELS_KEY}
          values={genaiSettings[MODELS_KEY]}
          handleSyncSettings={handleSyncIntegrationSettings}
          changeIsLoading={setIsLoading}
        />
      ),
    },
  ];

  return (
    <Modal onHide={close}>
      <Dialog
        onClose={close}
        isLoading={isLoading}
        headerView={
          <div className="MSTeamsConfigurationDialog_header">{languageMap.generativeAi}</div>
        }
        contentView={
          isLoadingInit ? (
            <Loader />
          ) : (
            <Tabs>
              {tabs.map((tab, index) => (
                <div key={index} label={tab.label}>
                  {tab.component}
                </div>
              ))}
            </Tabs>
          )
        }
      />
    </Modal>
  );
};

export default GenerativeAIConfigurationDialog;
