import React, { useEffect, useState, useCallback, useReducer, createContext } from 'react';
import Axios from 'axios';
import { Dropdown, Input } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';
import { connect } from 'react-redux';
import Pagination from '../../components/Pagination/Pagination';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';

import Loader from 'components/common/Loader';
import FormGroup from 'components/common/FormGroup';
import AnnotationItem from 'pages/Annotations/AnnotationCard';
import NodeFilter from 'pages/Annotations/NodeFilter';
import Retrain from '../Flow/components/Retrain';

import {
  ANNOTATIONS_ENDPOINT,
  INTENTS_MAPPED_BY_NODES_ENDPOINT,
  ENTITIES_ENDPOINT,
  DELETE_ANNOTATION_ENDPOINT,
  VALIDATE_ANNOTATION_ENDPOINT,
  VALIDATE_ANNOTATIONS_ENDPOINT,
} from 'utils/constants/index';
import './Annotations.scss';
import languageMap from 'Language/Language';
import extractLanguageInfo from '../../utils/extractLanguageInfo';
import Clusters from './Clusters';
import DialogComponent from '../../components/Dialog/DialogComponent';
import BotResponse from './BotResponse';

const SET_FILTERS = 'SET_FILTERS';
const RESET_FILTERS = 'RESET_FILTERS';
const SET_SKIP = 'SET_SKIP';
// this maps the tab id to the validated_by values in db
const tab_map = { 0: 0, 1: 1, 2: -1, 3: 3 };
const initialFilters = {
  skip: 0,
  search: '',
  node: 'all',
};

export const IntentsByNodeContext = createContext({});
export const EntitiesContext = createContext({});

const Annotations = (props) => {
  const { projectId, languageOptions } = props;
  const [annotations, setAnnotations] = useState([]);
  const [annotationTab, setAnnotationTab] = useState(0);
  const [loadingAnnotations, setLoadingAnnotations] = useState(false);
  const [count, setCount] = useState(0);
  const [intentsByNode, setIntentsByNode] = useState({});
  const [entities, setEntities] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage);
  const [selectedAnnotations, setSelectedAnnotations] = useState({});
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [botResponse, setBotResponse] = useState([]);
  const [filters, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SET_FILTERS:
        return { ...state, skip: 0, ...action.payload };
      case SET_SKIP:
        return { ...state, skip: action.payload };
      case RESET_FILTERS:
        return initialFilters;
      default:
        return state;
    }
  }, initialFilters);

  const { node } = filters;

  const setSearch = useCallback(
    debounce((val) => {
      dispatch({ type: SET_FILTERS, payload: { search: val } });
    }, 300),
    []
  );

  /**
   * Fetch intents mapped by nodes/states
   */
  useEffect(() => {
    Axios.get(INTENTS_MAPPED_BY_NODES_ENDPOINT, { params: { projectId } })
      .then(({ data }) => setIntentsByNode(data))
      .catch(console.log);
  }, [projectId, setIntentsByNode]);

  /**
   * Fetch Entities
   */
  useEffect(() => {
    Axios.get(ENTITIES_ENDPOINT, { params: { projectId } })
      .then(({ data }) => setEntities(data))
      .catch(console.log);
  }, [projectId, setEntities]);

  const fetchAnno = useCallback(() => {
    const { skip, search, node } = filters;
    const lastNode = node === 'all' ? null : node;
    if (!selectedLanguage) {
      return;
    }
    setLoadingAnnotations(true);
    Axios.get(ANNOTATIONS_ENDPOINT, {
      params: {
        projectId,
        skip,
        searchPhrase: search || null,
        lastNode,
        language: selectedLanguage,
        validatedByUser: tab_map[annotationTab],
      },
    })
      .then(({ data }) => {
        const { results, count } = data;
        setCount(count);
        setAnnotations(results);
      })
      .catch(console.log)
      .finally(() => setLoadingAnnotations(false));
  }, [filters, projectId, tab_map[annotationTab], selectedLanguage]);

  useEffect(() => {
    fetchAnno();
  }, [fetchAnno]);

  useEffect(() => {
    if (!selectedLanguage && props.selectedLanguage) {
      setSelectedLanguage(props.selectedLanguage);
    }
  }, [props.selectedLanguage]);

  const updateAnnotation = useCallback(
    (newAnnotation) => {
      const newAnnotationsList = annotations.map((item) =>
        item.annotation_id === newAnnotation.annotation_id ? newAnnotation : item
      );
      setAnnotations(newAnnotationsList);
    },
    [annotations]
  );

  const deleteAnnotation = useCallback(
    (annotationId) => {
      setLoadingAnnotations(true);
      Axios.get(DELETE_ANNOTATION_ENDPOINT, {
        params: { projectId, annotationId },
      })
        .then(() => fetchAnno())
        .catch(console.error);
    },
    [projectId, fetchAnno]
  );

  const validateAnnotation = useCallback(
    (annotationId) => {
      const itemToBeValidated = find(annotations, (item) => item.annotation_id === annotationId);

      if (!itemToBeValidated) {
        return;
      }

      const newIntentId = get(itemToBeValidated, 'intent_id');
      const newUtteranceEntitiesFormat = get(itemToBeValidated, 'utterance');

      setLoadingAnnotations(true);

      Axios.get(VALIDATE_ANNOTATION_ENDPOINT, {
        params: {
          projectId,
          annotationId,
          newIntentId,
          newUtteranceEntitiesFormat,
        },
      })
        .then(() => fetchAnno())
        .catch(console.error);
    },
    [annotations, projectId, fetchAnno]
  );

  const validateSelected = useCallback(() => {
    const annotationArr = [];
    if (!Object.keys(selectedAnnotations).length) {
      return;
    }
    annotations.forEach((anno) => {
      if (anno.annotation_id in selectedAnnotations) {
        annotationArr.push({
          annotationId: anno.annotation_id,
          newIntentId: get(anno, 'intent_id'),
          newUtteranceEntitiesFormat: get(anno, 'utterance'),
        });
      }
    });

    setLoadingAnnotations(true);

    Axios.get(VALIDATE_ANNOTATIONS_ENDPOINT, {
      params: {
        projectId,
        annotations: annotationArr,
      },
    })
      .then(() => fetchAnno())
      .catch(console.error);
  }, [annotations, selectedAnnotations, projectId]);

  const toggleCheckAnnotation = useCallback(
    (annotationId) => {
      if (annotationId in selectedAnnotations) {
        const selected = { ...selectedAnnotations };
        delete selected[annotationId];
        setSelectedAnnotations(selected);
      } else {
        setSelectedAnnotations({ ...selectedAnnotations, [annotationId]: true });
      }
    },
    [selectedAnnotations, projectId]
  );

  const checkAllAnnotations = useCallback(() => {
    const selected = {};
    annotations.forEach((item) => (selected[item.annotation_id] = true));
    setSelectedAnnotations(selected);
  }, [annotations, projectId]);

  const setSkip = (skip) => {
    dispatch({ type: SET_SKIP, payload: skip });
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 3 || newValue === 4 || newValue === 2) {
      // Clusters
      setAnnotations([]);
    } else {
      setSkip(0);
    }
    setAnnotationTab(newValue);
  };
  const onChangeLanguage = (e, { value }) => {
    setSelectedLanguage(value);
  };

  return (
    <div className="Annotations">
      <div className="Annotations_Container_Filters">
        <Input
          placeholder={languageMap.search}
          icon="search"
          style={{ height: '38px', width: '300px' }}
          onChange={(e) => setSearch(e.target.value)}
        />

        <div style={{ display: 'flex', alignItems: 'end' }}>
          <FormGroup labelText={languageMap.state}>
            <NodeFilter
              selectedNode={node}
              onChange={(node) => dispatch({ type: SET_FILTERS, payload: { node } })}
            />
          </FormGroup>
          {languageOptions.length > 1 && (
            <Dropdown
              style={{ marginLeft: 12 }}
              selection
              value={selectedLanguage}
              options={languageOptions}
              onChange={onChangeLanguage}
            />
          )}
          <Retrain style={{ marginLeft: 16 }} />
        </div>
      </div>
      <div className="Annotations_Tabs">
        <MuiTabs
          className="Annotations_TabsRoot"
          indicatorColor="primary"
          value={annotationTab}
          onChange={handleTabChange}
        >
          <Tab key="new" label={languageMap.new} />
          <Tab key="validated_by_user" label={languageMap.validatedByUser} />
          <Tab key="invalidated_by_user" label={languageMap.invalidatedByUser} />
          <Tab key="flagged_content" label={languageMap.flaggedContent} />
          <Tab key="missing_content" label={languageMap.missingContent} />
        </MuiTabs>
        {annotationTab === 1 && (
          <Button
            color="primary"
            style={{ marginRight: 8 }}
            variant="contained"
            onClick={validateSelected}
          >
            {languageMap.validateSelected}
          </Button>
        )}

        {annotationTab === 1 && (
          <Button variant="contained" onClick={checkAllAnnotations}>
            {languageMap.selectAll}
          </Button>
        )}
      </div>
      {annotationTab !== 3 && annotationTab !== 4 && (
        <>
          {loadingAnnotations && <Loader className="Annotations_Loader" />}
          <div className="Annotations_ScrollContainer">
            {isEmpty(annotations) && !loadingAnnotations && (
              <div>{languageMap.noResultsForCurrentSearchCriteria}</div>
            )}
            <IntentsByNodeContext.Provider value={intentsByNode}>
              <EntitiesContext.Provider value={entities}>
                {annotations.map((item, index) => (
                  <AnnotationItem
                    key={item.annotation_id + '_' + index}
                    item={item}
                    updateAnnotation={updateAnnotation}
                    deleteAnnotation={deleteAnnotation}
                    validateAnnotation={validateAnnotation}
                    onCheck={annotationTab === 1 ? toggleCheckAnnotation : null}
                    annotationTab={annotationTab}
                    checked={selectedAnnotations[item.annotation_id] || false}
                    setResponseModalOpen={setResponseModalOpen}
                    setBotResponse={setBotResponse}
                  />
                ))}
              </EntitiesContext.Provider>
            </IntentsByNodeContext.Provider>
            <DialogComponent
              contentStyle={{ overflowY: 'auto' }}
              transitionDuration={100}
              isOpen={responseModalOpen}
              onClose={() => {
                setResponseModalOpen(false);
                setBotResponse([]);
              }}
            >
              <BotResponse blocks={botResponse} />
            </DialogComponent>
          </div>
          <Pagination total={count} skip={filters.skip} setPage={setSkip} pageCount={20} />
        </>
      )}
      {annotationTab === 3 && <Clusters projectId={projectId} contentType="flagged_content" />}
      {annotationTab === 4 && <Clusters projectId={projectId} contentType="missing_content" />}
    </div>
  );
};

export default connect(extractLanguageInfo)(Annotations);
