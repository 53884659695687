import React from 'react';
import { connect } from 'react-redux';

import ChatAvatar from 'pages/CRM/containers/CRMDashboard/components/ConversationsStreamContainer/components/ChatAvatar';
import languageMap from 'Language/Language';

import './ExternalConversationCard.scss';

const ExternalConversationCard = (props) => {
  const {
    elapsedTime,
    isConversationRead,
    lastMessage,
    departmentName,
    channelName,
    userDisplayName,
    isActive,
  } = props;

  const displayLastMessageContent = () => {
    if (
      lastMessage &&
      typeof lastMessage === ('string' || 'array') &&
      (lastMessage.includes('<html') || lastMessage.includes('DOCTYPE'))
    ) {
      return 'Email html content';
    } else if (lastMessage) {
      return lastMessage;
    } else {
      return languageMap.startAConversation;
    }
  };

  return (
    <div className={isActive ? 'ExternalConversationCard active' : 'ExternalConversationCard'}>
      <ChatAvatar msgSender={userDisplayName} />
      <div className="conversation-item-header-container">
        <div className="conversation-item-header">
          <div className="conversation-item-header-name-container">
            <div
              className={
                isConversationRead === 0
                  ? 'conversation-item-header-name-unread regular-text'
                  : 'conversation-item-header-name regular-text'
              }
            >
              {`${languageMap.from}: ${userDisplayName ? userDisplayName : 'Unknown user'}`}
            </div>
          </div>
          <div className="conversation-item-header-time regular-text">{elapsedTime}</div>
          <div className="conversation-item-header-burger-menu"></div>
        </div>
        <div
          className={
            isConversationRead === 0
              ? 'conversation-item-body-unread regular-text'
              : 'conversation-item-body regular-text'
          }
        >
          <span className="truncated-line">{displayLastMessageContent()} </span>
        </div>

        <div className="tags-container">
          <div className="tag department small-text">
            <span>{departmentName}</span>
          </div>
          <div className="tag channel small-text">
            <span>{channelName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(ExternalConversationCard);
