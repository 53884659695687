import React, { useState } from 'react';
import { connect } from 'react-redux';

import DropDown from 'components/common/DropDown';
import { selectAllLanguages } from 'store/crm/selectors';
import languageMap from 'Language/Language';

import './SettingTabGenerativeAIConfiguration.scss';
import HelperTooltip from 'components/HelperTooltip/HelperTooltip';

const SettingTabGenerativeAIConfiguration = ({
  languages,
  handleSyncSettings,
  language,
  setting,
}) => {
  const [selectedValue, setSelectedValue] = useState(language);
  const options = [{ label: languageMap.projectLanguage, value: null }, ...languages];
  const handleSelectionChange = (value) => {
    setSelectedValue(value);
    handleSyncSettings(setting, value);
  };

  return (
    <div className="SettingTabGenerativeAIConfiguration">
      <div className="option-text">{languageMap.processingLanguage}</div>
      <DropDown
        className="dropDown"
        options={options}
        text={options.find((option) => option.value === selectedValue)?.label}
        value={selectedValue}
        clearable={false}
        onSelect={handleSelectionChange}
      />
      <HelperTooltip
        className="tooltip"
        title={languageMap.processingLanguageInfo}
        placement="top"
        arrow
      ></HelperTooltip>
    </div>
  );
};

const mapStateToProps = (state) => ({
  languages: selectAllLanguages(state),
});

export default connect(mapStateToProps)(SettingTabGenerativeAIConfiguration);
