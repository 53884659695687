const ACCEPTS = [
  '.zip',
  'application/zip',
  'application/x-zip-compressed',

  '.pdf',
  'application/pdf',

  // --------------- documents:
  '.doc',
  'application/msword',

  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  '.odt',
  'application/vnd.oasis.opendocument.text',

  // --------------- sheets:
  '.xls',
  'application/vnd.ms-excel',

  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  '.ods',
  'application/vnd.oasis.opendocument.spreadsheet',

  // --------------- presentations:
  '.ppt',
  'application/vnd.ms-powerpoint',

  '.pptx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',

  '.odp',
  'application/vnd.oasis.opendocument.presentation',

  '.jpg',
  'image/jpg',

  '.jpeg',
  'image/jpeg',

  '.png',
  'image/png',

  '.gif',
  'image/gif',
];
export default ACCEPTS;
