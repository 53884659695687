import React from 'react';
import { v4 as uuid } from 'uuid';
import { format, startOfDay, subDays } from 'date-fns';
import {
  AccountBox as AccountBoxIcon,
  Event as EventIcon,
  Group as GroupIcon,
  Input as InputIcon,
  LocalOffer as LocalOfferIcon,
  Person as PersonIcon,
} from '@mui/icons-material';

const parseJsonMessage = (json) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return json;
  }
};

const stringify = (val) => {
  return typeof val === 'string' ? val : JSON.stringify(val);
};

export const parseMessage = (data) => {
  const parsedData = parseJsonMessage(data.message);
  let parsedMessage = parseJsonMessage(parsedData.message);
  const tempActions = stringify(parsedData.actions || []);
  const parsedActions = parseJsonMessage(tempActions).map((a) => parseJsonMessage(stringify(a))); // weird behaviour of json parse

  return {
    ...data,
    message: { ...parsedData, parsedMessage, parsedActions },
  };
};

export const processMessage = (json) => {
  if (!json) {
    return { parsedMessage: 'Missing Input', timestamp: 0 };
  }

  const { timestamp } = json;

  try {
    if (json?.message === null) {
      return { parsedMessage: 'New Internal Conversation', timestamp };
    }

    const parsedMessage = JSON.parse(json?.message);

    if (typeof parsedMessage === 'object') {
      return { parsedMessage: parsedMessage?.text || '', timestamp };
    } else {
      return { parsedMessage };
    }
  } catch (error) {
    const { timestamp } = json;

    if (typeof json?.message === 'object' && 'text' in (json?.message || {})) {
      return { parsedMessage: json?.message?.text || '', timestamp };
    } else {
      return { parsedMessage: json?.message || '', timestamp };
    }
  }
};

export const getShareLink = (userId, conversationId) => {
  const url = window.location.href;

  if (url.slice(-9) === 'crm/inbox') {
    return `${url}/${userId}_${conversationId}`;
  } else if (url.slice(-10) === 'crm/inbox/') {
    return `${url}${userId}_${conversationId}`;
  } else {
    return `${url}`;
  }
};

export const mainMenuItems = [
  { id: uuid(), icon: <AccountBoxIcon className="icon" />, name: 'User', type: 'standard' },
  { id: uuid(), icon: <LocalOfferIcon className="icon" />, name: 'Status', type: 'standard' },
  { id: uuid(), icon: <GroupIcon className="icon" />, name: 'Department', type: 'standard' },
  { id: uuid(), icon: <PersonIcon className="icon" />, name: 'Agent', type: 'standard' },
  { id: uuid(), icon: <InputIcon className="icon" />, name: 'Channel', type: 'standard' },
  { id: uuid(), icon: <EventIcon className="icon" />, name: 'Date', type: 'standard' },
];

export const secondaryMenuDates = () => {
  const today = Date.now();
  const startOfToday = format(startOfDay(today), 'T');

  const yesterday = subDays(today, 1);
  const startOfYesterday = format(startOfDay(yesterday), 'T');

  const thirtyDaysAgo = format(startOfDay(subDays(today, 30)), 'T');
  const ninetyDaysAgo = format(startOfDay(subDays(today, 90)), 'T');

  return [
    {
      id: uuid(),
      name: 'Date',
      label: 'Today',
      min_date: startOfToday,
      max_date: format(today, 'T'),
    },
    {
      id: uuid(),
      name: 'Date',
      label: 'Yesterday',
      min_date: startOfYesterday,
      max_date: format(yesterday, 'T'),
    },
    {
      id: uuid(),
      name: 'Date',
      label: 'Last Thirty Days',
      min_date: thirtyDaysAgo,
      max_date: format(today, 'T'),
    },
    {
      id: uuid(),
      name: 'Date',
      label: 'Last Ninety Days',
      min_date: ninetyDaysAgo,
      max_date: format(today, 'T'),
    },
  ];
};

export const secondaryMenuStatuses = () => {
  return [
    { id: uuid(), name: 'Status', label: 'Open', value: 1 },
    { id: uuid(), name: 'Status', label: 'Closed', value: 0 },
  ];
};

export const getParams = (filters, projectId, startFromConversationIndex) => {
  const params = {
    project_id: projectId,
    start_from_conversation_index: startFromConversationIndex,
  };

  filters.forEach((filter) => {
    if (filter.name === 'User') {
      if (!params.user_email) {
        params.user_email = [];
      }
      params.user_email.push(filter.value);
    }
    if (filter.name === 'Status') {
      params.conversation_status = filter.value;
    }
    if (filter.name === 'Department') {
      if (!params.department_id) {
        params.department_id = [];
      }
      params.department_id.push(filter.value);
    }
    if (filter.name === 'Agent') {
      if (!params.agent_id) {
        params.agent_id = [];
      }
      params.agent_id.push(filter.value);
    }
    if (filter.name === 'Channel') {
      if (!params.channel) {
        params.channel = [];
      }
      params.channel.push(filter.value);
    }
    if (filter.name === 'Date') {
      params.min_date = filter.min_date;
      params.max_date = filter.max_date;
    }
    if (filter.type === 'custom') {
      if (!(filter.id in params)) {
        params[filter.id] = [];
      }

      params[filter.id].push(filter.value);
    }
  });

  return params;
};

export const getOption = (item, currentFilter) => {
  if (currentFilter.name === 'User') {
    return { id: item?.id, name: 'User', label: item?.name, value: item?.name };
  }
  if (currentFilter.name === 'Status') {
    return { id: item?.id, name: item?.name, label: item?.label, value: item?.value };
  }
  if (currentFilter.name === 'Department') {
    return {
      id: item?.department_id,
      name: currentFilter.name,
      label: item?.department_name,
      value: item?.department_id,
    };
  }
  if (currentFilter.name === 'Agent') {
    return {
      id: item?.agent_id,
      name: currentFilter.name,
      label: item?.agent_name,
      value: item?.agent_id,
    };
  }
  if (currentFilter.name === 'Channel') {
    return {
      id: item?.id,
      name: currentFilter.name,
      label: item?.name,
      value: item?.id,
    };
  }
  if (currentFilter.name === 'Date') {
    return item;
  }
  if (currentFilter.type === 'custom') {
    return {
      id: item?.id,
      name: item.name,
      label: item?.name,
      value: item?.name,
      type: item?.type,
    };
  }
};
