import React, { Fragment, useState } from 'react';
import { TextArea } from 'semantic-ui-react';
import FormGroup from 'components/common/FormGroup';
import Axios from 'axios';

import OptionCheckbox from 'pages/Flow/components/Message/MessageBlocks/components/OptionCheckbox';
import { FILE_UPLOAD_ENDPOINT, UPLOAD_SCOPE_PUBLIC_PROJECT_FILES } from 'utils/constants/index';

import './ImageBlock.scss';
import languageMap from 'Language/Language';
import InputText from '../../../../../../components/common/input/InputText';
import AcceptBrowse from '../../../../../../components/Browse/AcceptBrowse';

const ImageBlock = ({ block, onBlockChange, voiceInteraction, projectId, loadingChange }) => {
  const { titleLabel, url, text, image, tts_on, alternative_text_on, alternative_text } = block;

  const [imageURL, setImageURL] = useState(image);
  const [currentUrl, setCurrentUrl] = useState(url);
  const [title, setTitle] = useState(titleLabel);
  const saveBlock = (fieldToChange) => {
    onBlockChange({ ...block, ...fieldToChange });
  };

  const onUrlChange = (name, value) => {
    setCurrentUrl(value);
  };

  const onTitleChange = (name, value) => {
    setTitle(value);
  };

  const handleImageURLChange = (name, value) => {
    setImageURL(value);
  };

  const handleImageUpload = (picture) => {
    let formData = new FormData();
    formData.append('file', picture[0]);
    formData.append('scope', UPLOAD_SCOPE_PUBLIC_PROJECT_FILES);
    saveStoredImage(formData);
  };

  const saveStoredImage = async (formData) => {
    loadingChange(true);
    const result = await Axios.post(FILE_UPLOAD_ENDPOINT + '/' + projectId, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
    loadingChange(false);
    if (result.data?.link) {
      saveBlock({ image: result.data.link });
      handleImageURLChange(result.data.link);
    }
  };

  const handleCheckboxChange = (checkboxToChange) => {
    onBlockChange({ ...block, ...checkboxToChange });
  };

  const renderImageInfoFields = () => {
    return (
      <Fragment>
        <FormGroup labelText={languageMap.title}>
          <InputText
            value={title}
            className="ImageBlock_TitleLabel"
            onValueChanged={onTitleChange}
            onBlur={(e) => saveBlock({ titleLabel: e.target.value })}
          />
        </FormGroup>
        <FormGroup labelText={languageMap.titleURL}>
          <InputText
            value={currentUrl}
            className="ImageBlock_TitleURL"
            onValueChanged={onUrlChange}
            onBlur={(e) => saveBlock({ url: e.target.value })}
          />
        </FormGroup>
        <FormGroup labelText={languageMap.image}>
          <div className="ImageBlock_ImageContainer">
            <InputText
              value={imageURL}
              className="ImageBlock_Image"
              onValueChanged={handleImageURLChange}
              onBlur={(e) => saveBlock({ image: e.target.value })}
            />
            <AcceptBrowse
              style={{ marginLeft: 8 }}
              text={languageMap.chooseImage}
              onBrowse={handleImageUpload}
              accepts={['.jpeg', '.jpg', '.png', '.gif']}
            />
          </div>
        </FormGroup>
        <FormGroup labelText={languageMap.text}>
          <TextArea
            className="ImageBlock_Input"
            defaultValue={text}
            minheight={37}
            rows={8}
            onBlur={(e) => saveBlock({ text: e.target.value })}
          />
        </FormGroup>
      </Fragment>
    );
  };

  const renderTTSOption = () => {
    return (
      voiceInteraction && (
        <OptionCheckbox
          checked={tts_on || false}
          onChange={() => handleCheckboxChange({ tts_on: !tts_on })}
          title={languageMap.ttsOnLabel}
        />
      )
    );
  };

  const renderAltTextOption = () => {
    return (
      voiceInteraction &&
      tts_on && (
        <OptionCheckbox
          checked={alternative_text_on || false}
          onChange={() =>
            handleCheckboxChange({
              alternative_text_on: !alternative_text_on,
            })
          }
          title={languageMap.alternativeText}
        />
      )
    );
  };

  const renderOptionalSettings = () => {
    return (
      <div className="ImageBlock_OptionalSettings">
        {renderTTSOption()}
        {renderAltTextOption()}
      </div>
    );
  };

  const renderAlternativeTextArea = () => {
    return (
      voiceInteraction &&
      tts_on &&
      alternative_text_on && (
        <TextArea
          className="ImageBlock_TextArea"
          defaultValue={alternative_text}
          minheight={37}
          rows={15}
          onBlur={(e) => {
            saveBlock({ alternative_text: e.target.value });
          }}
        />
      )
    );
  };

  return (
    <div className="ImageBlock">
      {renderImageInfoFields()}
      {renderOptionalSettings()}
      {renderAlternativeTextArea()}
    </div>
  );
};

export default ImageBlock;
