import React, { Component } from 'react';
import { produce } from 'immer';
import Axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import Eye from '@mui/icons-material/Visibility';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';

import { normalizeString } from '../../../utils/utils';
import languageMap from 'Language/Language';
import UtteranceText from './UtteranceText';
import { MAIN_COLOR } from '../../../mainTheme';

class Entity extends Component {
  // props : value, name, onDelete
  constructor(props) {
    super(props);
    this.container_style = {
      position: 'relative',
      flexShrink: 0,
    };

    this.style = {
      marginLeft: 4,
      marginRight: 4,
      borderRadius: '16px',
      backgroundColor: '#646464',
      color: 'white',
      padding: '5px',
      fontSize: 14,
      fontWeight: 500,
    };

    this.cancel_style = {
      marginLeft: '10px',
      fontSize: '21px',
    };

    this.state = {
      tooltip_style: {
        visibility: 'hidden',
        position: 'absolute',
        backgroundColor: 'rgb(233, 233, 233)',
        borderRadius: '16px',
        padding: 10,
        top: 0,
        left: 0,
      },
    };
  }

  componentDidMount() {
    let tooltip_rect = this.tooltip_ref.getBoundingClientRect();
    let value_rect = this.value_ref.getBoundingClientRect();

    this.setState(
      produce((draft) => {
        draft.tooltip_style.left = value_rect.width / 2 - tooltip_rect.width / 2;
        draft.tooltip_style.top = -5 - tooltip_rect.height;
      })
    );
  }

  setTooltipVisibility(value) {
    this.setState(
      produce((draft) => {
        draft.tooltip_style.visibility = value;
      })
    );
  }

  render() {
    const { value, onDelete, name, isDisabled } = this.props;
    return (
      <div style={this.container_style}>
        <span
          ref={(e) => (this.value_ref = e)}
          style={this.style}
          onMouseOut={this.setTooltipVisibility.bind(this, 'hidden')}
          onMouseOver={this.setTooltipVisibility.bind(this, 'visible')}
        >
          {value}
          <span onClick={isDisabled ? null : onDelete} style={this.cancel_style}>
            x
          </span>
        </span>
        <span ref={(e) => (this.tooltip_ref = e)} style={this.state.tooltip_style}>
          {name}
        </span>
      </div>
    );
  }
}

const deleteButtonStyle = {
  padding: 3,
  marginRight: 8,
  color: '#F50057',
};

const eyeButtonStyle = {
  padding: 3,
  marginRight: 8,
  color: 'black',
};

export default class Utterance extends React.Component {
  constructor(props) {
    super(props);

    this.style = {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 3,
      paddingTop: 3,
      paddingBottom: 3,
      marginLeft: 3,
      marginRight: 3,
      paddingRight: 10,
      maxWidth: 'calc(100% - 10px)',
      // overflowX: 'auto',
    };

    this.state = {
      tokens: _.clone(this.props.data.tokens),
      autocomplete_visibility: Boolean(this.props.data.autocomplete_visibility),
    };

    let tokens = this.state.tokens;

    if (tokens[0].type !== 'text') {
      tokens.unshift({ type: 'text', value: '' });
    }

    if (tokens[tokens.length - 1].type !== 'text') {
      tokens.push({ type: 'text', value: '' });
    }

    for (let i = 1; i < tokens.length - 1; i++) {
      if (tokens[i].type === 'entity' && tokens[i + 1].type === 'entity') {
        tokens.splice(i + 1, 0, { type: 'text', value: '' });
      }
    }
  }

  handleNewEntity(src_token_index, entity_id, entity_name, selection_start, selection_end) {
    let src_token = this.state.tokens[src_token_index];

    let entity_token = {
      type: 'entity',
      value: src_token.value.substring(selection_start, selection_end),
      entity_id,
      entity_name,
      language: this.props.language,
    };

    let suffix_token = {
      type: 'text',
      value: src_token.value.substring(selection_end),
    };

    Axios.post('/internal_api/entity/mark_value', entity_token).then((response) => {
      if (response.data.status === 'error') {
        alert(response.data);
      }
      this.setState(
        produce((draft) => {
          draft.tokens[src_token_index].value = src_token.value.substring(0, selection_start);
          draft.tokens.push(entity_token);
          draft.tokens.push(suffix_token);
          this.props.onSave(_.cloneDeep(draft.tokens));
        })
      );
    });
  }

  handleTextChange(index, newValue) {
    let value = normalizeString(newValue).toLowerCase();

    this.setState(
      produce((draft) => {
        draft.tokens[index].value = value;

        this.props.onSave(draft.tokens);
      })
    );
  }

  handleDeleteEntity(index) {
    this.setState(
      produce((draft) => {
        let prev = draft.tokens[index - 1];
        let next = draft.tokens[index + 1];

        prev.value += draft.tokens[index].value;

        prev.value += next.value;

        draft.tokens.splice(index, 2);

        this.props.onSave(_.cloneDeep(draft.tokens));
      })
    );
  }

  handleToggleVisibility = () => {
    this.setState(
      produce((draft) => {
        draft.autocomplete_visibility = !draft.autocomplete_visibility;
        this.props.onToggleVisibility();
      })
    );
  };

  renderToken = (isShort, isDisabled) => (token, idx) => {
    if (token.type === 'text') {
      return (
        <UtteranceText
          entities={this.props.entities}
          key={idx}
          value={token.value}
          isShort={isShort}
          isDisabled={isDisabled}
          onNewEntity={this.handleNewEntity.bind(this, idx)}
          onSave={this.handleTextChange.bind(this, idx)}
          hasConflict={this.props?.data?.has_conflict}
        />
      );
    } else {
      return (
        <Entity
          key={idx}
          value={token.value}
          name={token.entity_name}
          isDisabled={isDisabled}
          onDelete={this.handleDeleteEntity.bind(this, idx)}
        />
      );
    }
  };

  render() {
    let utterance;
    const { tokens, autocomplete_visibility } = this.state;
    const { onDelete } = this.props;
    const isShort = tokens?.length > 2;

    utterance = tokens.map(this.renderToken(isShort));

    return (
      <div style={this.style}>
        <Tooltip title={languageMap.delete}>
          <IconButton style={deleteButtonStyle} onClick={onDelete}>
            <Delete />
          </IconButton>
        </Tooltip>
        <Tooltip title={languageMap.toggleAutocompleteVisibility}>
          <IconButton
            style={{
              ...eyeButtonStyle,
              color: autocomplete_visibility ? MAIN_COLOR : '#818181',
            }}
            onClick={this.handleToggleVisibility}
          >
            <Eye />
          </IconButton>
        </Tooltip>

        {utterance}
      </div>
    );
  }
}
