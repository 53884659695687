import Axios from 'axios';
import { TOKEN_KEY_PREFIX } from '../../utils/constants';

let clientKey = '';

export const authenticate = async (projectId) => {
  await Axios.get('/internal_api/msteams/clientKey', {
    params: { projectId },
  })
    .then((response) => {
      clientKey = response.data.clientKey;
    })
    .catch((error) => {
      console.error('Response MsTeams error', error);
    });

  if (localStorage.getItem(TOKEN_KEY_PREFIX + projectId) !== null) {
    return Promise.resolve(localStorage.getItem(TOKEN_KEY_PREFIX + projectId));
  } else {
    const response = await Axios.get(
      'https://' +
        process.env.REACT_APP_DOMAIN_NAME +
        '/api/registerAnonymous?channel=msteams&clientKey=' +
        clientKey +
        '&projectId=' +
        projectId
    );
    localStorage.setItem(TOKEN_KEY_PREFIX + projectId, response.data.token);
    return response.data.token;
  }
};
