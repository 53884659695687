export const CONVERSATIONS_ENDPOINT = '/internal_api/chat_history/list';
export const OWN_CONVERSATIONS_ENDPOINT = '/internal_api/chat_history/list_assigned_to_me';
export const SEND_MESSAGE_ENDPOINT = '/internal_api/wisevoice_crm_client/send_message';
export const GET_FILTERS_ENDPOINT = '/internal_api/wisevoice_crm/get_filters';
export const IS_USER_ONLINE_ENDPOINT = '/internal_api/wisevoice_crm/is_user_online';
export const UNASSIGNED_COUNT = '/internal_api/chat_history/unassigned_count';
export const NEXT_UNASSIGNED_CONV = '/internal_api/chat_history/next_unassigned_conversation';
export const ANNOTATIONS_ENDPOINT = '/internal_api/annotations/list';
export const ANNOTATION_CLUSTERS_ENDPOINT = '/internal_api/annotations/getClusters';
export const NODES_ENDPOINT = '/internal_api/annotations/listStates';
export const ENTITIES_ENDPOINT = '/internal_api/annotations/getProjectEntities';
export const INTENTS_MAPPED_BY_NODES_ENDPOINT = '/internal_api/annotations/getNodesIntentsMap';
export const DELETE_ANNOTATION_ENDPOINT = '/internal_api/annotations/delete';
export const VALIDATE_ANNOTATION_ENDPOINT = '/internal_api/annotations/validate';
export const VALIDATE_ANNOTATIONS_ENDPOINT = '/internal_api/annotations/validateMany';
export const FILE_UPLOAD_ENDPOINT = `internal_api/upload_file`;
export const TRANSLATE_OBJECT = `/internal_api/translate_object`;

export const AGENTS = '/internal_api/department/get_agents';
export const DEPARTMENTS = '/internal_api/department/get_departments';
export const CONVERSATIONS = '/internal_api/conversation/get_conversations';
export const CONVERSATION_META = '/internal_api/conversation/get_conversation_meta';
export const CHAT_HISTORY = '/internal_api/chat_history/get';
export const GET_MEDIA_AND_FILES = '/internal_api/conversation/list_media_files';
export const UPDATE_CONVERSATION_SUBJECT =
  '/internal_api/internal_conversation/update_conversation_subject';
export const CREATE_NEW_CONVERSATION =
  '/internal_api/internal_conversation/create_internal_conversation';

export const UPDATE_CONVERSATION_STATUS = '/internal_api/conversation/update_conversation_status';
export const ASSIGN_CONVERSATION = '/internal_api/conversation/assign_conversation';
export const CHECK_SESSION = '/internal_api/check_session';
export const CONVERSATION_NOTES = '/internal_api/conversation/update_conversation_notes';
export const LAST_MESSAGE = '/internal_api/conversation/update_last_message';
export const UPDATE_LAST_EVENT_TIMESTAMP = '/internal_api/conversation/update_last_event_timestamp';
export const UPDATE_READ_STATUS = '/internal_api/conversation/update_read_status';
export const ASSIGN_AGENT = '/internal_api/conversation/assign_conversation_to_agent';
export const ASSIGN_DEPARTMENT = '/internal_api/conversation/assign_conversation_to_department';

export const SET_CONVERSATION_READ = '/internal_api/conversation/set_conversation_read';

export const MACROS_GET = '/internal_api/macros/get_macros';
export const MACROS_CREATE = '/internal_api/macros/create_macro';
export const MACROS_DELETE = '/internal_api/macros/delete_macro';
export const MACROS_EDIT = '/internal_api/macros/edit_macro';

export const USERS_SUGGESTIONS =
  '/internal_api/conversation/search_for_users_autocomplete_suggestions';
export const FILTERED_CONVERSATIONS = '/internal_api/conversation/get_filtered_conversations';

export const ADD_AGENT_PARTICIPANT = '/internal_api/internal_conversation/add_user_participant';
export const REMOVE_AGENT_PARTICIPANT =
  '/internal_api/internal_conversation/remove_user_participant';
export const ADD_DEPARTMENT_PARTICIPANT =
  '/internal_api/internal_conversation/add_department_participant';
export const REMOVE_DEPARTMENT_PARTICIPANT =
  '/internal_api/internal_conversation/remove_department_participant';

export const SET_ADMIN = '/internal_api/internal_conversation/set_admin';
export const UNSET_ADMIN = '/internal_api/internal_conversation/unset_admin';

export const EDIT_USER_INFO = '/internal_api/user/edit_user_info';

export const GENERATE_RESET_PASSWORD_TOKEN = '/internal_api/user/generate_password_reset_token';

export const endOfDialogNode = {
  name: 'End of dialog',
  object_class: 'endOfDialog',
  object_id: 0,
  prev: [],
  next: {},
};

export const returnToParentFlowNode = {
  name: 'Return to Parent Flow',
  object_class: 'returnToParentFlow',
  object_id: -2,
  prev: [],
  next: {},
};

export const menuOptions = [
  {
    label: 'Log Out',
    returnValue: 'log_out',
  },
];

export const crmEndpoint =
  process.env.NODE_ENV === 'production'
    ? `https://${process.env.REACT_APP_CRM_DOMAIN}:${process.env.REACT_APP_CRM_PORT}`
    : `https://${process.env.REACT_APP_CRM_DOMAIN_DEV}:${process.env.REACT_APP_CRM_PORT_DEV}`;

export const crmSocketParams = {
  timeout: 10000,
  reconnectionDelayMax: 3000,

  reconnection: true,
  reconnectionAttempts: 100,
  reconnectionDelay: 1000,
};

export const PDF_OPTION = 'pdf';
export const XLSX_OPTION = 'xlsx';
export const CSV_OPTION = 'csv';

export const MENU_ANNOTATIONS = 'Learning';
export const MENU_COLLECTIONS = 'Collections';
export const MENU_FLOWS = 'Flows';
export const MENU_QNA = 'QnA';
export const MENU_INTEGRATIONS = 'Integrations';
export const MENU_CHANGE_LOGS = 'Change logs';
export const MENU_GENERATIVE_AI = 'Generative AI';

export const MENU_OVERVIEW = 'Overview';
export const MENU_TEAM_PERFORMANCE = 'Team performance';
export const MENU_WISE_AGENT = 'Wise Agent';
export const MENU_FEEDBACK = 'Feedback';

export const BOT = 'bot';
export const REPORTS = 'reports';
export const SETTINGS = 'settingsMenu';
export const PROFILE = 'profile';
export const PROJECT_SETTINGS = 'projectSettings';
export const USERS_MANAGEMENT = 'users';
export const DEPARTMENTS_MANAGEMENT = 'departments';

export const COLLECTION_INTENTS = 'intents';
export const COLLECTION_ENTITIES = 'entities';
export const COLLECTION_ENTITY_TYPES = 'entity_types';
export const COLLECTION_SERVICES = 'services';
export const COLLECTION_MESSAGES = 'messages';
export const COLLECTION_SCRIPTS = 'scripts';
export const TOKEN_KEY_PREFIX = 'Wisevoice-Token-';

export const MENU_ICON_SIZE = '18px';

// Scope used for storage. A scope controls:
// - the persistence (e.g. old chat-files are removed depending on each project's persistence config)
// - whether the file gets a public url (e.g. gen-ai docs are private while chat-files get public url for download)
// - where the file is saved (for better organization)
export const UPLOAD_SCOPE_PUBLIC_PROJECT_FILES = 'public-project-files'; // profile images, flow images (img block)
export const UPLOAD_SCOPE_CHAT_FILES = 'chat-user-files'; // any file sent in a conversation (by external or internal user)
export const UPLOAD_SCOPE_GEN_AI = 'gen-ai'; // private documents used for generative AI, uploaded by internal users
