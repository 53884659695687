import React, { useState } from 'react';
import Cropper from 'cropperjs';
import DialogComponent from '../../../components/Dialog/DialogComponent';
import { getRoundedCanvas, resizeImage } from './resizeImage';
import { uploadFile } from '../../../utils/uploadFile';
import { useParams } from 'react-router-dom';
import { srcToFile } from '../../../utils/utils';
import { setLoading } from '../../../store/global/actions';
import { connect } from 'react-redux';
import languageMap from '../../../Language/Language';
import { UPLOAD_SCOPE_PUBLIC_PROJECT_FILES } from '../../../utils/constants';

const ProfilePhoto = ({ onClose, imageSrc, token, onChangeImage, dispatch }) => {
  const [cropper, setCropper] = useState(null);
  const { id: projectId } = useParams();
  const createCrop = () => {
    const image = document.getElementById('imageToCrop');

    const cropper = new Cropper(image, {
      viewMode: 3,
      cropBoxResizable: false,
      aspectRatio: 1,
    });
    setCropper(cropper);
  };

  const onProceed = async () => {
    const croppedCanvas = cropper?.getCroppedCanvas();
    const imageType = 'image/png';
    const imageData = croppedCanvas.toDataURL(imageType);
    const resizedCanvas = await resizeImage(imageData, imageType);
    const rounded = getRoundedCanvas(resizedCanvas).toDataURL('image/png');
    // const saveImg = document.createElement('a'); // New link we use to save it with
    // saveImg.href = rounded; // Assign image src to our link target
    // saveImg.download = 'profile.png'; // set filename for download
    // document.body.appendChild(saveImg); // Add link to page
    // saveImg.click();
    onClose();
    dispatch(setLoading(true));
    const file = await srcToFile(rounded, 'profile.png', 'image/png');
    const response = await uploadFile(
      file,
      projectId,
      token,
      null,
      UPLOAD_SCOPE_PUBLIC_PROJECT_FILES
    );
    if (response?.data?.link) {
      onChangeImage(response?.data?.link);
    }
    dispatch(setLoading(false));
  };
  return (
    <DialogComponent
      isOpen={!!imageSrc}
      onClose={onClose}
      onProceed={onProceed}
      contentStyle={{
        margin: 'auto',
        overflowY: 'auto',
        position: 'relative',
      }}
    >
      <div className="regular-text" style={{ margin: 20, whiteSpace: 'pre-wrap' }}>
        {languageMap.profileCropperInfo}
      </div>
      <img
        onLoad={createCrop}
        style={{ display: 'block', maxWidth: '100%', maxHeight: 400 }}
        id="imageToCrop"
        src={imageSrc}
      />
    </DialogComponent>
  );
};

export default connect(null)(ProfilePhoto);
