import React, { Component } from 'react';
import Axios from 'axios';
import debounce from 'lodash/debounce';
import languageMap from 'Language/Language';
import withNotifications from 'components/withNotifications';
import Dialog from 'pages/Flow/components/Dialog';
import Modal from 'pages/Flow/components/Modal';
import Loader from 'components/common/Loader';
import TokenEntryRow from './TokenEntryRow';
import './FacebookConfigurationDialog.scss';

const emptyRow = {
  page_name: '',
  page_id: '',
  page_access_token: '',
};

class FacebookConfigurationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      client_key: '',
      facebook_pages: [{ page_name: '', page_id: '', page_access_token: '' }],
    };

    this.debouncedStateSync = debounce(this.handleStateChanged, 500);
  }

  componentDidMount() {
    const { showError } = this.props;
    Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: this.props.projectId,
        type_id: 5,
      },
    })
      .then((response) => {
        let data = JSON.parse(response.data.data);
        const facebook_pages = data.facebook_pages || this.state.facebook_pages;

        if (!this.emptyEntryExists(facebook_pages)) {
          facebook_pages.push(emptyRow);
        }

        this.setState({
          isLoading: false,
          client_key: data.client_key || '',
          facebook_pages: facebook_pages || [{ page_name: '', page_id: '', page_access_token: '' }],
        });
      })
      .catch((error) => {
        showError(error.message);
        this.props.close();
      });
  }

  emptyEntryExists = (entries) => {
    let emptyEntryExists = false;

    entries.forEach((entry) => {
      if (!entry.page_name && !entry.page_id && !entry.page_access_token) {
        emptyEntryExists = true;
      }
    });

    return emptyEntryExists;
  };

  handleRemoveTokenEntry = (index) => {
    const { facebook_pages } = this.state;
    let new_facebook_pages = [...facebook_pages];
    new_facebook_pages.splice(index, 1);

    if (!this.emptyEntryExists(new_facebook_pages)) {
      new_facebook_pages.push(emptyRow);
    }

    this.setState({ facebook_pages: new_facebook_pages }, () => {
      this.debouncedStateSync();
    });
  };

  handleTokenEntryChange = (index) => (page_name, page_id, page_access_token) => {
    const { facebook_pages } = this.state;
    let new_facebook_pages = [...facebook_pages];
    new_facebook_pages[index] = { page_name, page_id, page_access_token };

    if (!this.emptyEntryExists(new_facebook_pages)) {
      new_facebook_pages.push(emptyRow);
    }

    if (this.emptyEntryExists(facebook_pages) && !page_name && !page_id && !page_access_token) {
      this.handleRemoveTokenEntry(index);
    } else {
      this.setState({ facebook_pages: new_facebook_pages }, () => {
        this.debouncedStateSync();
      });
    }
  };

  handleClientKeyChange = (event) => {
    const newClientKey = event.target.value;

    this.setState({ client_key: newClientKey }, () => {
      this.debouncedStateSync();
    });
  };

  handleStateChanged = () => {
    const { showError } = this.props;
    const { client_key, facebook_pages } = this.state;
    let new_facebook_pages = [...facebook_pages];

    if (this.emptyEntryExists(facebook_pages)) {
      new_facebook_pages.pop();
    }

    Axios.post('/internal_api/project/integration/update', {
      projectId: this.props.projectId,
      typeId: 5,
      column: 'data',
      value: JSON.stringify({ client_key, facebook_pages: new_facebook_pages }),
    }).catch((error) => {
      showError(error.message);
    });
  };

  renderFacebookPages() {
    const { facebook_pages } = this.state;
    return facebook_pages.map((entry, index) => {
      const { page_name, page_id, page_access_token } = entry;
      return (
        <TokenEntryRow
          key={index}
          pageName={page_name}
          pageId={page_id}
          pageAccessToken={page_access_token}
          remove={() => this.handleRemoveTokenEntry(index)}
          onChange={this.handleTokenEntryChange(index)}
        />
      );
    });
  }

  render() {
    const { client_key, isLoading } = this.state;
    const { close, projectId } = this.props;

    const webhookUrl =
      'https://' +
      process.env.REACT_APP_DOMAIN_NAME +
      '/api/client/facebook/' +
      client_key +
      '/' +
      projectId;

    return (
      <Modal onHide={close}>
        <Dialog
          onClose={close}
          headerView={<div className="FacebookConfigurationDialog_header">{languageMap.meta}</div>}
          contentView={
            isLoading ? (
              <Loader />
            ) : (
              <div className="FacebookConfigurationDialog">
                <p className="FacebookConfigurationDialog_Label">{languageMap.webhookUrl}</p>
                <input readOnly value={webhookUrl} />
                <p className="FacebookConfigurationDialog_Label">{languageMap.clientKey}</p>
                <input value={client_key} onChange={this.handleClientKeyChange} />
                <div className="FacebookConfigurationDialog_facebookPagesContainers">
                  <div className="FacebookConfigurationDialog_facebookPagesContainers_Header">
                    <div className="FacebookConfigurationDialog_facebookPagesContainers_Header_Column">
                      <span className="FacebookConfigurationDialog_facebookPagesContainers_Header_Column_Title">
                        {languageMap.pageName}
                      </span>
                    </div>
                    <div className="FacebookConfigurationDialog_facebookPagesContainers_Header_Column">
                      <span className="FacebookConfigurationDialog_facebookPagesContainers_Header_Column_Title">
                        {languageMap.pageId}
                      </span>
                    </div>
                    <div className="FacebookConfigurationDialog_facebookPagesContainers_Header_Column">
                      <span className="FacebookConfigurationDialog_facebookPagesContainers_Header_Column_Title">
                        {languageMap.pageAccessToken}
                      </span>
                    </div>
                  </div>
                  {this.renderFacebookPages()}
                </div>
              </div>
            )
          }
        />
      </Modal>
    );
  }
}

export default withNotifications(FacebookConfigurationDialog);
