import React, { Component } from 'react';
import Axios from 'axios';
import { produce } from 'immer';
import debounce from 'lodash/debounce';
import languageMap from 'Language/Language';

import Dialog from 'pages/Flow/components/Dialog';
import Modal from 'pages/Flow/components/Modal';
import Loader from 'components/common/Loader';
import InputText from 'components/common/input/InputText';
import DropDown from 'components/common/DropDown';
import withNotifications from 'components/withNotifications';
import './SipConfigurationDialog.scss';

export const soundOptions = [
  { label: 'None', value: null },
  { label: 'Sunet 1', value: 'Sunet 1' },
  { label: 'Sunet 2', value: 'Sunet 2' },
];

class SipConfigurationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        min_listen_time: 1,
        no_response_timeout: 1.1,
        time_exceeded: 10,
        background_sound: null,
      },
      isLoading: true,
    };
  }

  componentDidMount() {
    const { showError } = this.props;
    this.setState({ isLoading: true });
    Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: this.props.projectId,
        type_id: 4,
      },
    })
      .then((response) => {
        const data = JSON.parse(response.data.data);
        this.setState(
          produce((draft) => {
            draft.params.min_listen_time = data.min_listen_time || 1;
            draft.params.no_response_timeout = data.no_response_timeout || 1.1;
            draft.params.time_exceeded = data.time_exceeded || 10;
            draft.params.background_sound = data.background_sound || null;
            draft.isLoading = false;
          })
        );
      })
      .catch((e) => {
        showError(e.message);
      });
  }

  getNumber(string) {
    if (string) {
      return parseFloat(string);
    }
  }

  onValueChange = (key, value) => {
    const newValue = key === 'background_sound' ? value : this.getNumber(value);
    this.setState(
      {
        params: { ...this.state.params, [key]: newValue },
      },
      () => {
        this.debouncedStateSync(key);
      }
    );
  };

  debouncedStateSync = debounce((key) => {
    const { showError } = this.props;
    Axios.post('/internal_api/project/integration/update', {
      projectId: this.props.projectId,
      typeId: 4,
      column: 'data',
      value: JSON.stringify({
        [key]: this.state.params[key],
      }),
    }).catch((error) => showError(error.message));
  }, 500);

  renderContent = () => {
    const { isLoading, params } = this.state;
    const { min_listen_time, no_response_timeout, time_exceeded, background_sound } = params;

    return isLoading ? (
      <Loader />
    ) : (
      <div>
        <div className="SipConfigurationDialog_ParameterContainer">
          <InputText
            type="number"
            step="any"
            min="0"
            title="Minimum listen time"
            name="min_listen_time"
            value={min_listen_time}
            onValueChanged={this.onValueChange}
          />
        </div>
        <div className="SipConfigurationDialog_ParameterContainer">
          <InputText
            type="number"
            step="any"
            min="0"
            title="No response timeout"
            name="no_response_timeout"
            value={no_response_timeout}
            onValueChanged={this.onValueChange}
          />
        </div>
        <div className="SipConfigurationDialog_ParameterContainer">
          <InputText
            type="number"
            step="any"
            min="0"
            title="Time exceeded"
            name="time_exceeded"
            value={time_exceeded}
            onValueChanged={this.onValueChange}
          />
        </div>
        <div className="SipConfigurationDialog_ParameterContainer">
          <DropDown
            value={background_sound}
            defaultText="Sound"
            options={soundOptions}
            onSelect={(value) => this.onValueChange('background_sound', value)}
          />
        </div>
      </div>
    );
  };

  render() {
    const { close } = this.props;

    return (
      <Modal onHide={close}>
        <Dialog
          onClose={close}
          headerView={<div className="SipConfigurationDialog_HeaderTitle">{languageMap.sip}</div>}
          contentView={
            <>
              <h3 className="SipConfigurationDialog_ContactUsHeader">
                {languageMap.informationWindowText}
              </h3>
              {this.renderContent()}
            </>
          }
        />
      </Modal>
    );
  }
}

export default withNotifications(SipConfigurationDialog);
