import React from 'react';
import Help from '@mui/icons-material/Help';
import './HelperTooltip.scss';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';

const HelperTooltip = ({ style, title, className }) => {
  return (
    <Tooltip title={title}>
      <Help style={style} className={clsx(className, 'HelperTooltip')} />
    </Tooltip>
  );
};

export default HelperTooltip;
