import React from 'react';
import { AddButton } from 'components/common/Button/Buttons';
import browseFiles from './browseFiles';

// =====================================================================================================================
//  D E C L A R A T I O N S
// =====================================================================================================================

// =====================================================================================================================
//  C O M P O N E N T
// =====================================================================================================================
class AcceptBrowse extends React.PureComponent {
  render() {
    const { className, text, variant, color, style } = this.props;
    return (
      <AddButton
        style={style}
        className={className}
        variant={variant || 'contained'}
        color={color || 'primary'}
        onClick={this.onBrowseClick}
        text={text}
      />
    );
  }

  // -----------------------------------------------------------------------------------------------------------------
  //  I N T E R N A L
  // -----------------------------------------------------------------------------------------------------------------
  /**
   *
   */
  onBrowseClick = () => {
    const { onBrowse, accepts, multiple, params } = this.props;
    browseFiles({ onBrowse, accepts, multiple, params });
  };
}

export default AcceptBrowse;
