import React from 'react';
import Checkbox from 'components/common/LabeledCheckbox/Checkbox';

import './Integration.scss';

const Integration = ({ integrationTypeId, onChange, onClick, configurable, title, active }) => {
  const handleValueChange = (event) => {
    onChange(integrationTypeId, event.target.checked);
  };

  const handleIntegrationClick = () => {
    onClick(integrationTypeId);
  };

  return (
    <div className="Integration">
      <div
        className={configurable ? 'title is-configurable' : 'title'}
        onClick={configurable ? handleIntegrationClick : undefined}
      >
        {title}
      </div>
      <Checkbox checked={active} onChange={handleValueChange} />
    </div>
  );
};

export default Integration;
